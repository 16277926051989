<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Owner App</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>Owner App</h1>
                        </div>
                        <p>This is the <a href="javascript:void(0)" @click="$router.push({ path: '/package-disabler'})">Package Disabler App</a> for android device other than Samsung and LG. In order to activate the app, you need a computer. (FYI: Computer is needed at activating time only).</p>
                        <div class="iframe-container">
                            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/pKfKvTePpdg" title="Power Cleaner (How to use power cleaner?)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp py-5" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">If you like reading instead of watching video tutorial, then proceed:</h5>
                        </div>
                        <p>Steps To Enable Device Owner Mode:</p>
                        <div class="row g-0 mb-3">
                            <ul style="text-align: start">
                                <li><h6>Install the package disabler owner app.</h6></li>
                                <li>
                                <h6>Download SDK Platform-Tools from
                                https://developer.android.com/<span style="display: inline-block">studio/releases</span><br>/platform-tools and extract it. Open the platform tools
                                folder. You will see folders like api etc.</h6>
                                </li>
                                <li>
                                <h6>Copy this path(example: copy C:\Users\vipin\Downloads\<span style="display: inline-block">platform-tools_r30.0.4-windows\platform-tools). Make this
                                    path globally accessible. Or you can open cmd and change
                                    directory to reach this path also.</span></h6>
                                </li>
                                <li>
                                <h6>Go to settings-&gt;About Device -&gt; build number and click on it
                                7+ times till it enables “Developer mode”</h6>
                                </li>
                                <li>
                                <h6>Go to settings-&gt;developer options and enable “usb debugging”.</h6>
                                </li>
                                <li>
                                <h6>Plug the mobile device into the computer using USB cable.</h6>
                                </li>
                                <li>
                                <h6>Go to settings-&gt;Accounts on your mobile device and remove all
                                google, microsoft, amazon and other accounts. Note: backup
                                your data if you like, before this operation. Removing the
                                accounts will not cause any lose of your data.</h6>
                                </li>
                                <li>
                                <h6>On the computer open a Terminal window and type
                                <mark>adb devices</mark> and press Enter key.<br>
                                Your mobile device might prompt you with a trust dialog. Click
                                accept. And run the following command:<br><br>
                                <mark>adb shell dpm set-device-owner
                                    com.pdp.deviceowner/.receivers.<span style="display: inline-block">AdminReceiver</span></mark></h6>
                                </li>
                                <li><h6>Reboot your device</h6></li>
                                <li><h6>Now you can add back your google and other accounts.</h6></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    data() {
        return {
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "PDOT", recurringIdentifier: "PD" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    }, 
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Maximize control and efficiency with the Owner App – your ultimate Android management solution: experience streamlined control, security, and customization. Download now!");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Empower Android Control | Download Owner App for Efficiency");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Package Disabler Owner App, android package disabler, Package Disabler Apk, package disabler Owner App download");*/
    },
}
</script>