<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Key Mapper</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>Device Key Mapper</h1>
                        </div>
                        <p>The key Mapping application is designed for Android devices – a robust tool that allows you to redefine the functions of your physical keys. With this application, you wield the ability to assign any desired action or application to each key on your device. Unlocking this capability takes your device interaction to a level beyond anything you've experienced before, offering a personalized and user-friendly experience that sets new standards in customization and ease of use.</p>
                        <h2><b>Why Choose Device Key Mapper from Package Disabler</b></h2>
                        <p>Utilizing a hardware key mapper app can significantly enhance your smartphone experience, making it more enjoyable and efficient. Here are some key benefits of using a hardware key mapper app:</p>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Time-Saving Efficiency:</b> Access your most-used apps or functions effortlessly with a single key press, eliminating the need to search for icons or navigate menus. This not only saves time but also simplifies your device interaction, providing quick and direct access to the features you use most. Say goodbye to unnecessary steps – just press a key and get things done effortlessly.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Boost Multitasking:</b> A hardware key mapper app can help you switch between apps or tasks with ease. You can map keys to open recent apps, take screenshots, or adjust settings. This makes your device more versatile and flexible.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Improve Gaming Experience:</b> If you love playing games on your smartphone, you will love using a hardware key mapper app. You can map physical keys to in-game actions, such as shooting, jumping, or moving. This gives you more control and accuracy over your gameplay. No more struggling with touch controls - just press a key and play!</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Enhanced Accessibility:</b> Improve the accessibility of your smartphone by assigning essential functions to easily reachable keys. This can be particularly beneficial for users with specific accessibility needs. You can map keys to quickly access accessibility features, such as voice assistant, magnification, or talkback. This makes your device more responsive and adaptable to your needs.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Reduced Clutter:</b> Minimize the clutter on your home screen or navigation bar by consolidating functions onto a few customizable keys. This not only simplifies the interface but also reduces visual distraction.</h6>
                        </div>
                        <h3><b>Who Can Use Device KEY MAPPER?</b></h3>
                        <p>A hardware key mapper app is a great app for anyone who wants to customize their physical keys and make their device more convenient and efficient. Whether you are a power user, a gamer, an accessibility user, or just someone who wants more control over your device, you can benefit from using a hardware key mapper app. You can use it for various purposes, such as:</p>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Studying:</b> Map key to open your notes, books, or online courses.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Working:</b> Map key to open your email, calendar, or documents.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Gaming:</b> Map key to launch your games, switch between windows, or record your gameplay.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Browsing:</b> Map key to open your favorite websites, bookmarks, or tabs.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Entertainment:</b> Map key to play music, watch videos, or stream content.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Emergency calling:</b> Map key to launch dialer application.</h6>
                        </div>
                        <h3><b>Key Features</b></h3>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Personalized Customization:</b> Tailor your smartphone to your unique preferences by assigning specific functions or actions to each physical key. This personalized customization ensures that your device aligns perfectly with your usage habits.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>User-Friendly Interface:</b> Enjoy a straightforward and intuitive interface, making key mapping a simple and hassle-free process. Easily navigate through the app to assign, reassign, or modify key functions with minimal effort.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Quick Task Switching:</b> Effortlessly switch between tasks or applications with mapped keys, reducing the need for navigating through multiple screens. This feature enhances multitasking and overall device efficiency.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Quick Key Swapping:</b> Swap key functions on-the-fly to adapt to different tasks or activities. Switch between configurations effortlessly without the need for extensive setup.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Cloud Control:</b> Cloud portal access available to manage the device and key mappings.</h6>
                        </div>
                        <h4><b>How to Login</b></h4>
                        <ol>
                            <li>After the purchase access cloud through URL: <a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a></li>
                            <li>On the login page enter email id and password. (Use the email id and password entered during the purchase)</li>
                            <li>After logging in, user will directed to the dashboard page. User can <a href="https://www.packagedisabler.com/payment?id=6585511b0add22a904d91044">download key mapper mobile application</a> from the 'DOWNLOAD APK' section on the page or utilize the link shared via email upon purchase.</li>
                            <li>
                                Afterward, open the Key Mapper app on your device. On the initial screen, you will find two login options.
                                <ol>
                                    <li>
                                        Enter the same email id and password used during the cloud login.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Key Mapper App Login">
                                        </div>
                                    </li>
                                    <li>Scan the QR code displayed in the cloud dashboard.</li>
                                    <div class="position-relative h-100">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="appQrCodeLogin" alt="Key Mapper App QR Code Login">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="WebQrCode" alt="Key Mapper Qr Code">
                                    </div>
                                </ol>
                            </li>
                            <li>
                                Upon successful login, users will have directed to main window.
                                <p>For detailed guidance on accessing the cloud and the application, please refer to the Cloud Access Guide and Application Access Guide sections respectively.</p>
                            </li>
                        </ol>
                        <h4><b>Cloud access guide</b></h4>
                        <p>Purchase the hardware key mapper application from the package disabler website. Then login to the cloud portal (<a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a>) with your credentials to manage the devices.</p>
                        <ol>
                            <li>Select the Devices menu on the left side panel
                                <ol>
                                    <li>In Devices menu window user can see the list of available devices. Select the respective serial number to access the device</li>
                                    <li>To add new device please follow the steps Mobile Application Access Guide.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceList" alt="Key Mapper Devices">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                            <li>After selecting the serial number it will redirect you key mapper profile window. On the profile window user could see 2 pages, KEY MAPPING and DEVICES.
                                <ol>
                                    <li><b>KEY MAPPING</b> On the key mapping page user can view mapped key details as well as can add new key mappings by selecting Key name, Key event and Package name.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webKeyMappingTab" alt="Key Mapper Mapping Detail">
                                        </div>
                                        <p>In “Select Key Name” multiple choises are available.</p>
                                        <p>In “Select Key Event” total 3 choices are available - Single tap, Double tap and Long press.</p>
                                        <p>In “Package Name” user should enter the application package name user want to map to the key and click on ADD button to map the key to respective application.</p>
                                    </li>
                                    <li><b>DEVICES:</b> User can view the device details.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceTab" alt="Key Mapper Device Detail">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <h4><b>Mobile Application Access Guide</b></h4>
                        <p>Download KEY MAPPER apk from the purchase email or the cloud portal and install it.</p>
                        <ol>
                            <li>Launch the KEY MAPPER application and press the SCAN QR CODE icon to scan the QR code or enter the user id and password to login.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Key Mapper Application Login">
                                </div>
                            </li>
                            <li>
                                Upon login user will redirected to main page.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appHomeScreenSmall" alt="Key Mapper Application Home Page">
                                </div>
                            </li>
                            <li>
                                On the Hardware key mapper screen user will find three distinct boxes with various selection options.
                                <ol>
                                    <li>
                                        the hardware key section, user will find a drop-down list featuring all available physical keys on the device. Choose the key user wish to customize from this list.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppKeyDropDown" alt="Key Mapper Key List">
                                        </div> 
                                    </li>
                                    <li>
                                        In the Action type section, user will find a dropdown menu featuring three distinct choices. Simply choose the option that aligns with user's customization requirement.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppKeyTypeDropDown" alt="Key Mapper Key Type">
                                        </div> 
                                    </li>
                                    <li>
                                        Next, enter the desired application name into the search box. Select the app and click on the add button.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppPackageTextBox" alt="Key Mapper Package Name">
                                        </div> 
                                    </li>
                                </ol> 
                            </li>
                            <li>
                                The application is successfully mapped to the corresponding hardware key now.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appMapped" alt="Mey Mapper">
                                </div>  
                            </li>
                            <li>
                                For viewing details about the app, simply click on the information icon ("i") displayed on the screen.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppInformation" alt="Application Information">
                                </div>  
                            </li>
                            <li>
                                To log out from the app, select the logout button located at the top-right corner of the screen.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppLogout" alt="App Logout">
                                </div>  
                            </li>
                        </ol>
                        <h4><b>Frequently Asked Questions</b></h4>
                        <div class="accordion" v-if="tableData.length > 0" id="faqExample">
                            <div class="card" v-for="(item, index) in tableData" :key="item._id">
                                <div class="card-header p-2" :id="'heading'+index">
                                    <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
                                    {{ item.question }}
                                    </button>
                                </h5>
                                </div>
                                <div :id="'collapse'+index" :class="{ show: index==0 }" class="collapse" :aria-labelledby="'heading'+index" data-parent="#faqExample">
                                    <div class="card-body" v-html="item.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    props: {
        appLogin: {
            type: String,
            default: "./img/keymapper/AppLogin.png",
        },
        appQrCodeLogin: {
            type: String,
            default: "./img/keymapper/AppQrCodeLogin.png",
        },
        WebQrCode: {
            type: String,
            default: "./img/keymapper/WebQrCode.png",
        },
        appHomeScreen: {
            type: String,
            default: "./img/urlblocker/AppHomeScreen.png",
        },
        webKeyMappingTab: {
            type: String,
            default: "./img/keymapper/webKeyMappingTab.png",
        },
        webDeviceList: {
            type: String,
            default: "./img/urlblocker/WebDeviceList.png",
        },
        
        webDeviceTab: {
            type: String,
            default: "./img/urlblocker/WebDeviceTab.png",
        },
        appHomeScreenSmall: {
            type: String,
            default: "./img/keymapper/homeScreenSmall.png",
        },
        AppKeyDropDown: {
            type: String,
            default: "./img/keymapper/AppKeyDropDown.png",
        },
        AppKeyTypeDropDown: {
            type: String,
            default: "./img/keymapper/AppKeyTypeDropDown.png",
        },
        AppPackageTextBox: {
            type: String,
            default: "./img/keymapper/AppPackageTextBox.png",
        },
        appMapped: {
            type: String,
            default: "./img/keymapper/appMapped.png",
        },
        AppInformation: {
            type: String,
            default: "./img/keymapper/AppInformation.png",
        },
        AppLogout: {
            type: String,
            default: "./img/keymapper/AppLogout.png",
        }
    },  
    data() {
        return {
            tableData: [
                {
                    _id: 1,
                    question: "1. How to purchase the key mapper app?",
                    answer:"User can purchase the app from <a href='https://www.packagedisabler.com/'>https://www.packagedisabler.com/</a>"
                },
                {
                    _id: 2,
                    question: "2. How to download the key mapper app?",
                    answer:"After the purchase user will receive the email with apk download link or download the app from the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>)."
                },
                {
                    _id: 3,
                    question: "3. How to login into the portal?",
                    answer:"User can login to the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>) with email id and password, use the same email id and password used during the purchase. "
                },
                {
                    _id: 4,
                    question: "4. How to login to the application after purchasing the app?",
                    answer:"On the login screen, either user can enter email id and password to login or scan the QR code. QR code available in the portal dashboard."
                },
                {
                    _id: 5,
                    question: "5. Did key mapper apps work in all Android devices?",
                    answer:"No. Key mapper application only available to Samsung devices."
                },
                {
                    _id: 6,
                    question: "6. If purchased for 1 device and wanted to purchase more devices, how to purchase?",
                    answer:"Navigate to the could portal homepage and utilize the 'BUY PLAN' option; there you will find the option to update your plan."
                },
                {
                    _id: 7,
                    question: "7. Why key mapping is not working?",
                    answer:"Delete the assigned mapping and try again"
                },
                {
                    _id: 8,
                    question: "8. Why I am not able to login?",
                    answer:"Check the license status in the cloud portal."
                },
                {
                    _id: 9,
                    question: "9. How to delete assigned key mapping?",
                    answer:"<b>In the application:</b> On the home page, locate the application you wish to remove. Next to the application name, find the delete button and click on it. <br> <b>In the portal:</b> Navigate to the Key Mapper profile page, click on “X” besides the application assignment."
                },
                {
                    _id: 10,
                    question: "10. Why the application need admin permission?",
                    answer:"The request for admin permission is essential for the proper functioning of the app"
                },
                {
                    _id: 11,
                    question: "11. Can I add new features to this app?",
                    answer:"Please contact the support team for further details."
                },
                {
                    _id: 12,
                    question: "12. Can I white label this app?",
                    answer:"Please contact the support team for further details."
                },
            ],
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "KMOT", recurringIdentifier: "KM" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    },  
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Redefine your Android device's keys effortlessly with our Key Mapping App. Experience unparalleled customization and ease of use, setting new standards in device interaction.");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Key Mapping for Android Devices for Boundless Possibilities.");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Device Key Mapper, Key Mapper, Hardware Key Mapper, package disabler ");*/
    },
}
</script>