import http from "../http-common";

class HomeDataService {
    videoList() {
        return http.get("video/list");
    }
    subscribeNewsletter(data) {
        return http.post("newsletter/create", data);
    }
    contactUs(data) {
        return http.post("contactus/create", data);
    }
    faqList(data) {
        return http.get("faq/list", { params: data });
    }
    createUser(data) {
        return http.post("admin/users/create", data);
    }
    blogList(data) {
        return http.get("blog/list", { params: data });
    }
    blogDetail(data) {
        return http.get("blog/detail", { params: data });
    }
    createPageViewLog(data) {
        return http.post("page/view", data);
    }
}

export default new HomeDataService();