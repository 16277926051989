<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12 text-center">
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Start -->
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
                    <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                    <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                </div>
                <div class="row g-5 mb-5">
                    <div class="col-lg-4">
                        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style="width: 60px; height: 60px;">
                                <i class="fa fa-phone-alt text-white"></i>
                            </div>
                            <div class="ps-4">
                                <h5 class="mb-2">Call to ask any question</h5>
                                <h4 class="text-primary mb-0">+442031294012</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style="width: 60px; height: 60px;">
                                <i class="fa fa-envelope-open text-white"></i>
                            </div>
                            <div class="ps-4">
                                <h5 class="mb-2">Email to get free quote</h5>
                                <h4 class="text-primary mb-0">packagedisabler@gmail.com</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style="width: 60px; height: 60px;">
                                <i class="fa fa-map-marker-alt text-white"></i>
                            </div>
                            <div class="ps-4">
                                <h5 class="mb-2">Visit our office</h5>
                                <h4 class="text-primary mb-0">London, England, WC2A 2JR</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                        <form ref="contactUsForm">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <input v-model="name" type="text" class="form-control border-0 bg-light px-4" placeholder="Your Name" style="height: 55px;">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" v-model="email" class="form-control border-0 bg-light px-4" placeholder="Your Email" style="height: 55px;">
                                </div>
                                <div class="col-12">
                                    <input type="text" v-model="subject" class="form-control border-0 bg-light px-4" placeholder="Subject" style="height: 55px;">
                                </div>
                                <div class="col-12">
                                    <textarea v-model="message" class="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="button" :disabled="isDisabled" @click="contactUs()">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                        <iframe class="position-relative rounded w-100 h-100"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=London+WC2A+2JR+(Package%20Disabler)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            frameborder="0" style="min-height: 350px; border:0;" allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact End -->

    </div>
</template>

<script>
// @ is an alias to /src
import HomeDataService from "../services/HomeDataService";
import {useToast} from 'vue-toast-notification';
const $toast = useToast();
export default {
  name: 'ContactUs',
  components: {
    
  },
  data() {
    return {
        isDisabled: false,
        email:"",
        name:"",
        subject:"",
        message:"",

    };
  },
  methods: {
    contactUs() {
        this.isDisabled = true;
        let data =  { email: this.email, name: this.name, subject: this.subject, message: this.message }
        HomeDataService.contactUs(data)
        .then((response) => {
            this.$refs.contactUsForm.reset();
            this.email="";this.name="",this.subject="";this.message="";
            this.isDisabled = false;
            $toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            $toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
  },
  created () {
    let currentUrl = window.location.href;
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical !== null) {
        canonical.href = currentUrl;
    }
  },
}
</script>
