<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Package Disabler</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>Package Disabler App</h1>
                        </div>
                        <p>The Package Disabler app is designed for NON-rooted Samsung devices and <a href="javascript:void(0)" @click="$router.push({ path: '/owner-app'})">Package disabler owner app</a> for all Android device offers the ability to deactivate unwanted apps or packages. This app contributes to enhancing device performance, conserving battery life, liberating storage space, and facilitating personalized device configurations. It stands out as the sole non-root solution enabling users to disable any undesired pre-installed or user-installed packages on their phone or tablet. Additionally, it provides cloud features such as backing up and restoring the disabled list and remote device management.</p>
                        <h4><b>Why Package Disabler?</b></h4>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i>It enables users to disable any undesired pre-installed or user-installed packages on their phone or tablet.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i>Offers convenient one-click functionality for disabling apps, ensuring a hassle-free process. When an application or package is disabled using this app, it is effectively rendered inactive. It won't be uninstalled or updated through Google Play or any other means, providing users with a reliable way to control app behavior on their devices.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i>App enhances security measures by incorporating password protection and uninstall protection. This ensures that the disabled apps cannot be re-enabled or uninstalled without proper authorization, preventing misuse and maintaining the integrity of the user's preferences and settings.</h6>
                        </div>
                        <h4><b>Key Features</b></h4>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Disable Unwanted Apps:</b> Package Disabler allows you to deactivate pre-installed or system apps that you may not use, freeing up valuable resources and decluttering your device.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Enhances Performance:</b> Disabling unnecessary apps can enhance your device's overall performance by reducing background processes and system load.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Improves Battery Life:</b> Unused apps often run in the background, consuming battery power. By disabling them, you can <a href="javascript:void(0)" @click="$router.push({ path: '/battery-booster'})">Boost Device Battery Life</a>.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Free Up Storage Space:</b> Deactivating apps you don't need helps in freeing up storage space on your device, allowing you to utilize it for more important files and applications.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Backup and Restore:</b> The app provides the convenience of backing up your disabled app list, allowing you to restore it later if needed. This ensures you can revert changes or switch devices without losing your preferences.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Remote Device Management:</b> With Package Disabler, you can manage your device remotely, providing flexibility and control over your device's configurations even when you are not physically present.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>System Information:</b> Provides detailed information about the device sensors, CPU, display, network and other hardware details.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Security Features:</b> Incorporates security measures to prevent accidental or unauthorized changes to the disabled app list.</h6>
                        </div>
                        <h4><b>How to Login</b></h4>
                        <ol>
                            <li>After the purchase access cloud through URL: <a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a></li>
                            <li>On the login page enter email id and password. (Use the email id and password entered during the purchase)</li>
                            <li>After logging in, user will directed to the dashboard page. User can download mobile application from the 'DOWNLOAD APK' section on the page or utilize the link shared via email upon purchase.</li>
                            <li>
                                Afterward, open the Package Disabler app on your device. On the initial screen, you will find two login options.
                                <ol>
                                    <li>
                                        Enter the same email id and password used during the cloud login.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Package Disabler Login">
                                        </div>
                                    </li>
                                    <li>Scan the QR code displayed in the cloud dashboard.</li>
                                    <div class="position-relative h-100">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="appQrCodeLogin" alt="Package Disabler Qr Code Login">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="WebQrCode" alt="Package Disabler Login QR Code">
                                    </div>
                                </ol>
                            </li>
                            <li>
                                Upon successful login, users will have directed to main window.
                                <p>For detailed guidance on accessing the cloud and the application, please refer to the Cloud Access Guide and Application Access Guide sections respectively.</p>
                            </li>
                        </ol>
                        <h4><b>Cloud access guide</b></h4>
                        <p>Purchase the Package Disabler application from the package disabler website. Then login to the cloud portal (<a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a>) with your credentials to manage the devices.</p>
                        <ol>
                            <li>Select the Devices menu on the left side panel
                                <ol>
                                    <li>In Devices menu window user can see the list of available devices. Select the respective serial number to access the device
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceList" alt="Device List">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                            <li>Upon choosing the serial number, you will be redirected to the Package Disabler profile details window. Within this window, users can navigate through four distinct pages: APPLICATIONS, DEVICE, ADVANCE SETTINGS, and PIN, each providing specific information and options related to the profile.
                                <ol>
                                    <li>
                                        <b>APPLICATIONS:</b> On the APPLICATIONS page user can view list of all packages/application available on your device. 
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appList" alt="Application List">
                                        </div>
                                        <p>
                                            User can search for the applications/packages in this page using different criteria as below,
                                            <ul>
                                                <li>Enable the toggle button on the screen to display all apps including the system applications</li>
                                                <li>Search by entering the package name.</li>
                                                <li>Search for the appliaction with status: enabled/disabled packages</li>
                                                <li>Search for the appliaction with Install status: Installed/Uninstalled packages.</li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li><b>DEVICES:</b> User can view the device details.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceTab" alt="Device Detail">
                                        </div>
                                    </li>
                                    <li><b>ADVANCE SETTINGS:</b> User have multiple options to <a href="javascript:void(0)" @click="$router.push({ path: '/hardware-key-disabler'})">disable hardware keys</a>, Bluetooth, GPS, Bixby Packages etc.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webAdvanceSettingTab" alt="Device Advance Setting">
                                        </div>
                                    </li>
                                    <li><b>PIN:</b> User can set pin to the PD app to provide extra security.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="WebPinTab" alt="Pin">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <h4><b>Mobile Application Access Guide</b></h4>
                        <p>Download <a href="https://app.packagedisabler.com/login">Package Disabler apk</a> from the purchase email or the cloud portal and install it.</p>
                        <ol>
                            <li>Launch the Package Disabler application and click on the SCAN QR CODE icon to scan the QR code or enter the user id and password to login.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Package Disabler Login">
                                </div>
                            </li>
                            <li>
                                Upon logging in, users will be directed to the main page, offering a comprehensive view of all available packages on the device. Packages are displayed with varying font colors, with each color signifying a distinct group of packages. This color-coded system provides users with an efficient way to identify and categorize different sets of packages. 
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="MobAppList" alt="Application List">&nbsp;&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="MobAppList1" alt="Application List">
                                </div>
                            </li>
                            <li>
                                At the top of the screen, users can access a toolbar featuring various icons for quick navigation and functionality.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppTopPanel" alt="Package Disabler Application">
                                </div>
                                <ol>
                                    <li>
                                        There is an icon to alter the displaying of packages in either list or tile view, offering users a convenient way to customize their viewing preference.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppSwitchDisplayIcon" alt="Package Disabler Display Option">
                                        </div>
                                    </li>
                                    <li>
                                        Next icon serves as an indicator for filtering, presenting users with multiple filtering options. Users can select the most suitable option based on their specific needs and preferences.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppFilterIcon" alt="Package Disabler Filters">
                                        </div><br>
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppFilterList" alt="Package Disabler Filters">&nbsp;&nbsp;
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppFilterDetail" alt="Package Disabler Filters">
                                        </div>
                                    </li>
                                    <li>
                                        Next icon signifies Bloatware options, providing users with access to features and settings related to managing or <a href="javascript:void(0)" @click="$router.push({ path: '/quick-bloat-detox'})">handling bloatware</a> packages.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppBloatwareIcon" alt="Package Disabler Bloatware">
                                        </div><br>
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppBloatwareList" alt="Package Disabler Bloatware">&nbsp;&nbsp;
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppBloatwareDetail" alt="Package Disabler Bloatware">
                                        </div>
                                    </li>
                                    <li>
                                        Next icon indicates the settings, in the settings multiple options are available.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppSettingIcon" alt="Package Disabler Setting">
                                        </div><br>
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppSettingList" alt="Package Disabler Setting">&nbsp;&nbsp;
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppSettingList1" alt="Package Disabler Setting">
                                        </div>
                                        <ol>
                                            <li>
                                                <b>Premium Features:</b> The premium features include the capability to disable or enable the hardware key, allowing users to customize the functionality of physical buttons on their device.
                                                <div class="position-relative h-100">
                                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppPremium" alt="Premium Support">
                                                </div>
                                                <label><b>Disable Over the Air Updates:</b> Prevent automatic updates and installations of apps over the internet.</label>
                                                <label><b>Disable Bixby Packages:</b> Disable Bixby-related applications, providing a streamlined experience without Samsung's virtual assistant.</label>
                                                <label><b>Disable Cardboard Apps on Gear VR:</b> Disable virtual reality apps on Gear VR, optimizing performance.</label>
                                                <label><b>Disable Status Bar:</b> Hide the status bar at the top of the device screen for a clean and minimalistic display.</label>
                                                <label><b>Disable Home Key:</b> Disable the home button functionality for a customized navigation experience.</label>
                                                <label><b>Disable Back Key:</b> Disable the back button, preventing accidental exits from applications.</label>
                                                <label><b>Disable Power Key:</b> Disable the power button, preventing unintended device shutdowns.</label>
                                                <label><b>Disable Firmware Upgrade:</b> Block automatic firmware upgrades to maintain control over the device's software.</label>
                                                <label><b>Disable Volume Up Key:</b> Prevent the volume up button from altering your device's sound settings.</label>
                                                <label><b>Disable Volume Down Key:</b> Disable the volume down button to ensure stable audio settings.</label>
                                            </li><br>
                                            <li>
                                                <b>System Info:</b> The System Info option provides comprehensive details about your device. It includes information about the Android device, display specifications, network details, battery status, storage details, sensor information, as well as processor and CPU details.
                                                <div class="position-relative h-100">
                                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSystemInfo">
                                                </div>
                                            </li><br>
                                            <li>
                                                <b>Settings & Password</b>
                                                <label><b>Password settings:</b> User can set a pin and secure the applied settings of the PD app.</label>
                                                <label><b>Package Settings:</b> Hide System Packages will Conceals system packages from the list. And Upload System Packages on the Server will allows users to upload system package information to a server.</label>
                                                <label><b>Sorting:</b> Organize your packages with different sorting options - “Sort list by name, app name, or date” and “Sort application by order”.</label>
                                                <label>
                                                    <b>Package Details:</b> Get detailed control over packages, Hide list by
                                                    <br>Permissions: Conceal the permissions each app requests, ensuring privacy and control.
                                                    <br>All Activities: Keep app activities discreet by hiding them from view.
                                                    <br>All Services: Manage and organize app services effortlessly by concealing them as needed.
                                                    <br>All Receivers: Safeguard your device's communication channels by hiding app receivers.
                                                    <br>Shared Libs and Their Size: Conceal shared libraries and their sizes, streamlining your device's resource usage.
                                                    <br>Content Providers: Maintain control over content access by hiding the list of content providers.
                                                </label>
                                                <div class="position-relative h-100">
                                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSettingsAndPassword">
                                                </div>
                                            </li><br>
                                            <li>
                                                <b>Enable/Disable dark mode:</b> Turning on/off dark mode makes the background of your app or device dark instead of light. It's good for your eyes, especially in the dark or at night.
                                            </li><br>
                                            <li>
                                                <b>Help:</b> Take user to the web site, user can get help
                                            </li><br>
                                            <li>
                                                <b>FAQ:</b> Take user to the FAQ menu on the website.
                                            </li><br>
                                            <li>
                                                <b>More information:</b> Take user to the website where user can find more detailed information about the app.
                                            </li><br>
                                            <li>
                                                <b>Share:</b> Users can easily share the app with others using the share button
                                            </li><br>
                                            <li>
                                                <b>About:</b> clicking this option user can view the details of the installed PD app.
                                            </li><br>
                                            <li>
                                                <b>Logout:</b> Click on the logout option, a message will appear asking if you're sure you want to log out of the app. Logging out will delete all your data from the server. Click "OK" to confirm and successfully log out from the app.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Using the refresh button user can refresh the package/application list on the screen.
                                        <div class="position-relative h-100">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="AppRefreshIcon" alt="Package Disabler Refresh Application">
                                        </div>
                                    </li>
                                </ol> 
                            </li>
                            <li>
                                User can use the search box to search for the packages by entering the package/application name.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSearchTextBox" alt="Application Search">&nbsp;&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSearchTextBoxFilter" alt="Application Filter">
                                </div>
                                The 3 line icon right-side to the search box will allow user to use below options.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSearchTextBoxFilterList" alt="Application Filter">&nbsp;&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppSearchTextBoxFilterDetail" alt="Application Filter">
                                </div>
                            </li>
                            <li>
                                Users can enable or disable each application individually by using the tick box next to its name on the screen. Additionally, they can add an application to their favorite list by selecting the heart icon.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppEnabledDisabled" alt="Application Enabled / Disabled">
                                </div>
                            </li>
                            <li>
                                At the bottom of the screen, users will find three icons: "Installed," "Disabled," and "Favorite."
                                <p><b>Installed:</b> Clicking this icon allows users to view all installed packages/applications in the list.</p>
                                <p><b>Disabled:</b> Users can view all disabled packages/applications in the list by selecting this icon.</p>
                                <p><b>Favorite:</b> Clicking on the "Favorite" icon enables users to view all the packages/applications added to their favorite list</p>
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="AppBottomFilter" alt="Application Filter">
                                </div>
                            </li>
                        </ol>
                        <h4><b>Frequently Asked Questions</b></h4>
                        <div class="accordion" v-if="tableData.length > 0" id="faqExample">
                            <div class="card" v-for="(item, index) in tableData" :key="item._id">
                                <div class="card-header p-2" :id="'heading'+index">
                                    <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
                                    {{ item.question }}
                                    </button>
                                </h5>
                                </div>
                                <div :id="'collapse'+index" :class="{ show: index==0 }" class="collapse" :aria-labelledby="'heading'+index" data-parent="#faqExample">
                                    <div class="card-body" v-html="item.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    props: {
        appLogin: {
            type: String,
            default: "./img/pd/AppLogin.png",
        },
        appQrCodeLogin: {
            type: String,
            default: "./img/pd/AppQrCodeLogin.png",
        },
        WebQrCode: {
            type: String,
            default: "./img/keymapper/WebQrCode.png",
        },
        appHomeScreen: {
            type: String,
            default: "./img/urlblocker/AppHomeScreen.png",
        },
        appList: {
            type: String,
            default: "./img/pd/appListingimg.png",
        },
        webKeyMappingTab: {
            type: String,
            default: "./img/keymapper/webKeyMappingTab.png",
        },
        webDeviceList: {
            type: String,
            default: "./img/urlblocker/WebDeviceList.png",
        },
        
        webDeviceTab: {
            type: String,
            default: "./img/pd/WebDeviceDetail.png",
        },
        webAdvanceSettingTab: {
            type: String,
            default: "./img/pd/webAdvanceSettingTab.png",
        },
        WebPinTab: {
            type: String,
            default: "./img/pd/WebPinTab.png",
        },
        appHomeScreenSmall: {
            type: String,
            default: "./img/keymapper/AppHomeScreenSmall.png",
        },
        AppKeyDropDown: {
            type: String,
            default: "./img/keymapper/AppKeyDropDown.png",
        },
        AppKeyTypeDropDown: {
            type: String,
            default: "./img/keymapper/AppKeyTypeDropDown.png",
        },
        AppPackageTextBox: {
            type: String,
            default: "./img/keymapper/AppPackageTextBox.png",
        },
        appMapped: {
            type: String,
            default: "./img/keymapper/appMapped.png",
        },
        AppInformation: {
            type: String,
            default: "./img/keymapper/AppInformation.png",
        },
        AppLogout: {
            type: String,
            default: "./img/keymapper/AppLogout.png",
        },
        MobAppList: {
            type: String,
            default: "./img/pd/MobAppList.png",
        },
        MobAppList1: {
            type: String,
            default: "./img/pd/MobAppList1.png",
        },

        // Filters
        AppTopPanel: {
            type: String,
            default: "./img/pd/AppTopPanel.png",
        },
        AppSwitchDisplayIcon: {
            type: String,
            default: "./img/pd/AppSwitchDisplayIcon.png",
        },
        AppFilterIcon: {
            type: String,
            default: "./img/pd/AppFilterIcon.png",
        },
        AppFilterList: {
            type: String,
            default: "./img/pd/AppFilterList.png",
        },
        AppFilterDetail: {
            type: String,
            default: "./img/pd/AppFilterDetail.png",
        },
        AppBloatwareIcon: {
            type: String,
            default: "./img/pd/AppBloatwareIcon.png",
        },
        AppBloatwareList: {
            type: String,
            default: "./img/pd/AppBloatwareList.png",
        },
        AppBloatwareDetail: {
            type: String,
            default: "./img/pd/AppBloatwareDetail.png",
        },
        AppSettingIcon: {
            type: String,
            default: "./img/pd/AppSettingIcon.png",
        },
        AppSettingList: {
            type: String,
            default: "./img/pd/AppSettingList.png",
        },
        AppSettingList1: {
            type: String,
            default: "./img/pd/AppSettingList1.png",
        },

        // Search TextBox
        AppSearchTextBox: {
            type: String,
            default: "./img/pd/AppSearchTextBox.png",
        },
        AppSearchTextBoxFilter: {
            type: String,
            default: "./img/pd/AppSearchTextBoxFilter.png",
        },
        AppSearchTextBoxFilterList: {
            type: String,
            default: "./img/pd/AppSearchTextBoxFilterList.png",
        },
        AppSearchTextBoxFilterDetail: {
            type: String,
            default: "./img/pd/AppSearchTextBoxFilterDetail.png",
        },
        
        AppEnabledDisabled: {
            type: String,
            default: "./img/pd/AppEnabledDisabled.png",
        },
        AppBottomFilter: {
            type: String,
            default: "./img/pd/AppBottomFilter.png",
        },

        //AppPremium
        AppPremium: {
            type: String,
            default: "./img/pd/AppPremium.png",
        },
        AppSystemInfo: {
            type: String,
            default: "./img/pd/AppSystemInfo.png",
        },
        AppSettingsAndPassword: {
            type: String,
            default: "./img/pd/AppSettingsAndPassword.png",
        },
        AppRefreshIcon: {
            type: String,
            default: "./img/pd/AppRefreshIcon.png",
        }
    },  
    data() {
        return {
            tableData: [
                {
                    _id: 1,
                    question: "1. How to purchase the package disabler app?",
                    answer:"User can purchase the app from <a href='https://www.packagedisabler.com/'>https://www.packagedisabler.com/</a>"
                },
                {
                    _id: 2,
                    question: "2. How to download the package disabler app?",
                    answer:"After the purchase user will receive the email with apk download link or download the app from the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>)."
                },
                {
                    _id: 3,
                    question: "3. How to login into the portal?",
                    answer:"User can login to the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>) with email id and password, use the same email id and password used during the purchase. "
                },
                {
                    _id: 4,
                    question: "4. How to login to the application after purchasing the app?",
                    answer:"On the login screen, either user can enter email id and password to login or scan the QR code. QR code available in the portal dashboard."
                },
                {
                    _id: 5,
                    question: "5. Did package disabler apps work in all Android devices?",
                    answer:"Package disabler (Samsung) works on Samsung devices and Package Disabler (owner app) works on all Android."
                },
                {
                    _id: 6,
                    question: "6. What to do if I get “Operation Error” message?",
                    answer:"Purchase the latest app from our webpage. You have older version of the app."
                },
                {
                    _id: 7,
                    question: "7. What to do if I forget password?",
                    answer:"If you forget password, please contact: packagedisabler@gmail.com"
                },
                {
                    _id: 8,
                    question: "8. Can't see all the system package of my device in the cloud portal, what to do?",
                    answer:"Open Package Disabler app. Tap on settings icon -> Settings & Password -> Check the box next to 'Upload system packages on server'."
                },
                {
                    _id: 9,
                    question: "9. How to mark packages as favorite",
                    answer:"Select the heart button besides the package name."
                },
            ],
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "PDOT", recurringIdentifier: "PD" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    }, 
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Enhance your Android experience with Package Disabler Apps. Download the Package Disabler Apk to gain control and optimize your device.");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Get Package Disabler App for Control & Optimization!");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Package Disabler, android package disabler, Package Disabler Apk, package disabler download");*/
    },
}
</script>