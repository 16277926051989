<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Power Cleaner</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>Power Cleaner</h1>
                        </div>
                        <p>A <b>device cleaner</b> like our Power Cleaner app is the best and easiest way to optimize your device’s speed and performance. Clean out your caches and remove unwanted files quickly with the cleaner app and you will see your device’s effectiveness enhanced.</p>
                        <div class="row g-0 mb-3">
                            <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Modular wipe:</b> User can select exactly what they want to clean and clean it with just one click</h5>
                            <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Selective wipe:</b> User can select a data from WHICH the files and apps needs to be cleaned, also select WHAT items needs to be cleaned and WHEN to clean them.</h5>
                        </div>
                        <div class="iframe-container">
                            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/AIN7KhfvVtE" title="Power Cleaner (How to use power cleaner?)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-lg-5" style="min-height: 500px;">
                        <div class="position-relative h-100">
                            <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" :src="aboutPCImage" style="object-fit: contain;" alt="About Power Cleaner">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        <br>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <h4><b>DO DEVICE SPECIFICATIONS MATTER FOR POWER CLEANERS?</b></h4>
                        <p>A device’s spec sheet shouldn’t really matter if you intend to use the Power Cleaner app. Irrespective of whether the device is a high-end device, a low-end device, or even a mid-range device, the application will work without any hiccups.</p>
                        <h4><b>IS THE POWER CLEANER APP NECESSARY?</b></h4>
                        <p>A good <b>device cleaner</b> can help remove any junk file in your device quickly and efficiently, clearing up much-needed space in your device. What this means is that you get to maximize your device’s performance.</p>
                        <p>If your device lags in speed, it means there might be unwanted applications running in the background, and your storage space might be brimming with unnecessary apps. These can take up a lot of device RAM. When this happens, your device’s ability to execute essential functions is compromised.</p>
                        <p>To remedy this, a trusted <b>device cleaner</b> like the Power Cleaner can clear up lagging issues and extend your RAM capacity by deleting unwanted temporary files and apps. Idle cached data removal can boost speed and <a href="javascript:void(0)" @click="$router.push({ path: '/battery-booster'})">enhance battery performance</a> as well.</p>
                        <h4><b>Do Power Cleaner Apps Really Work?</b></h4>
                        <p>The Power Cleaner app is a high-quality cleaner product that can bring a noticeable boost to your device’s performance. Any mobile device would require regular maintenance to have effective and consistent optimum performance, and our <b>device cleaner</b> does the job easily. If your mobile device is experiencing slow speed and clogged memory, then you will have to get rid of all the junk inside the device to bring it back to speed.</p>
                        <p>Let’s take a look at some of the essential functionalities of the <b>device cleaner</b>.</p>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Virus Prevention:</b> If your mobile device has infected files that contain viruses, then the <b>device cleaner</b> can get rid of them. When you scan the device with the cleaner app, such viruses are caught under their radar and removed to mitigate the risk.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Security from Malware:</b> Often malware and bloatware can enter your device unknowingly, through browsers or other applications. These can cause performance problems as well as data and privacy attacks. Power Cleaner app can detect such malware and bloatware, remove them completely and thwart any threat to the device.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Clean Cache:</b> Caches fill up with saved data, temp files, and apps, clogging your RAM. This can slow down the device’s processing speed, especially during heavy-duty functions. The Power Cleaner app will clear out cache data, and free up RAM space to boost speed.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Increased Storage:</b> The <b>device cleaner</b> can forcefully stop any unwanted applications or programs from running. This can create more RAM storage and free up space for other functions to run efficiently.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Fix File Manager Issues:</b> Sometimes file management can be a hassle, especially if you have a corrupted file hiding within your device folders. A device cleaner can detect and get rid of those files easily.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Create File Backup:</b> The Power Cleaner app automatically creates a backup, which helps you to recover any deleted files that you may want.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Remove Unwanted Apps:</b> Any idle application that may be present can be removed without any hiccups by a <b>device cleaner</b>. Such idle apps can run in the background and take up valuable processing and battery power, so removing them enhances your device’s performance.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Delete Unwanted Downloads:</b> When using mobile devices, a lot of files are downloaded, many of which are used just once and then left to occupy valuable storage space. This can pile up and eventually fill your device’s free space. Clear up your Downloads folder using the device cleaner and redeem some much-needed storage space.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Optimise Your Gallery: </b> Using a <b>device cleaner</b> you can automatically find bad photos or images in your gallery and delete them to make space. Find duplicate or similar photos, sort out images according to date and quality, optimize their size, etc., and either delete the unwanted ones or keep those that you need in the cloud.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Performance Tune-Up:</b> Device cleaners can force-stop performance-heavy applications and hibernate apps to enhance battery life and boost speed. Stopping such draining apps can help you manage your phone’s resources efficiently.</h6>
                        </div>
                        <h4><b>Make your Phone Cleaner and Quicker</b></h4>
                        <p>The Power Cleaner app saves you a lot of time and effort in enhancing your device’s functionality and efficiency. You gain more storage space, improve your device’s processing speed, and enhance the system's performance.</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Service Start -->
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
                    <h5 class="fw-bold text-primary text-uppercase">Key Features</h5>
                </div>
                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-mobile text-white"></i>
                            </div>
                            <h4 class="mb-3">Silent Application Uninstall</h4>
                            <p class="m-0">Applications can be uninstalled based on date range. User interaction is not needed</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-bug text-white"></i>
                            </div>
                            <h4 class="mb-3">Clear APP data</h4>
                            <p class="m-0">Application data can be cleaned to free up space. Date based filtering is possible</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-chart-pie text-white"></i>
                            </div>
                            <h4 class="mb-3">Clear Call Logs</h4>
                            <p class="m-0">Call history can be wiped based on date filter</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-check text-white"></i>
                            </div>
                            <h4 class="mb-3">Clean browse history and cookies</h4>
                            <p class="m-0">All browser history and cookies from Chrome, Firefox, etc will be cleaned</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-search text-white"></i>
                            </div>
                            <h4 class="mb-3">Files</h4>
                            <p class="m-0">Files can be filtered based on date and type and be cleaned</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-lock text-white"></i>
                            </div>
                            <h4 class="mb-3">Trigger function</h4>
                            <p class="m-0">Wipe rules can be specified and wipe operation will run on REBOOT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    props: {
        aboutPCImage: {
        type: String,
        default: "./img/PCInfo.png",
        },
    },
    data() {
        return {
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "PCOT", recurringIdentifier: "PC" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    }, 
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Optimize your Android with Power Cleaner. The ultimate phone cleaner for a faster, cleaner experience. Say goodbye to clutter and explore virus-free, optimized journeys with the Device Cleanup Tool.");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Maximize Android Performance: Power Cleaner's Ultimate Solution! ");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Device cleaner apk, Cleaner APK for Android, phone cleaner for android free download, virus cleaner apk for android, Download Clean Master APK for Android, Zero Cleaner APK, Download Device Cleanup Tool");*/
    },
}
</script>