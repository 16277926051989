<template>
    <div class="content">
        <div class="wrapper-1">
            <div class="wrapper-2">
                <h1>Thank you !</h1>
                <div class="jumbotron" style="box-shadow: 2px 2px 4px #000000;padding:10px;background:#defff0;">
                    <h3 class="text-center">Thank you for your payment, it’s processing</h3>
                    
                    <p class="text-center">Your order # is: 100000023</p>
                    <p class="text-center">You will receive an order confirmation email with details of your subscription.</p>
                    <br>
                    <ul :style=" { 'list-style-type': 'none' }">
                        <li><b>Email :</b> divyesh@gmail.com</li>
                        <li><b>Product Name :</b> Package Disabler</li>
                        <li><b>Qty :</b> 5</li>
                        <li><b>Amount :</b> $50</li>
                    </ul>
                </div>
                <button class="go-home">
                    go home
                </button>
            </div>
            <div class="footer-like">
                <p>
                    Email not received?
                    <a href="#">Click here to send again</a>
                </p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.wrapper-1{
  width:100%;
  height:100vh;
  display: flex;
flex-direction: column;
}
.wrapper-2{
  padding :30px;
  text-align:center;
}
h1{
    font-family: 'Kaushan Script', cursive;
  font-size:2.5em;
  letter-spacing:3px;
  color:#198754 ;
  margin:0;
  margin-bottom:20px;
}
.wrapper-2 p{
  margin:0;
  font-size:1.3em;
  color:#aaa;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing:1px;
}
.go-home{
  color:#fff;
  background:#5892FF;
  border:none;
  padding:10px 50px;
  margin:30px 0;
  border-radius:30px;
  text-transform:capitalize;
  box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);
}
.footer-like{
  margin-top: auto; 
  background:#D7E6FE;
  padding:6px;
  text-align:center;
}
.footer-like p{
  margin:0;
  padding:4px;
  color:#5892FF;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing:1px;
}
.footer-like p a{
  text-decoration:none;
  color:#5892FF;
  font-weight:600;
}

@media (min-width:360px){
  h1{
    font-size:2.5em;
  }
  .go-home{
    margin-bottom:20px;
  }
}

@media (min-width:600px){
  .content{
  max-width:1000px;
  margin:0 auto;
}
  .wrapper-1{
  height: initial;
  max-width:620px;
  margin:0 auto;
  margin-top:50px;
  box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
}
  
}
</style>