<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12 text-center">
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Blogs</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Start -->
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-8">
                        <!-- Blog Detail Start -->
                        <div class="mb-5">
                            <img class="img-fluid w-100 rounded mb-5" :src="`${this.imageBaseUrl}/blog/${blog.image}`" alt="Blog Image">
                            <h1 class="mb-4">{{ blog.title }}</h1>
                            <div v-html="blog.longDescription"></div>
                        </div>
                        <!-- Blog Detail End -->
                    </div>
        
                    <!-- Sidebar Start -->
                    <div class="col-lg-4">
                        <!-- Category Start -->
                        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s" v-if="category.length > 0">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="mb-0">Categories</h3>
                            </div>
                            <div class="link-animated d-flex flex-column justify-content-start">
                                <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" v-for="cat in category" :key="cat._id" href="javascript:void(0)" @click="$router.push({ path: 'blogs', query: { category: cat._id }})">{{ cat._id }}</a>
                            </div>
                        </div>
                        <!-- Category End -->

                    </div>
                    <!-- Sidebar End -->
                </div>
            </div>
        </div>
        <!-- Blog End -->
    </div>
</template>
<script>
// @ is an alias to /src
import HomeDataService from "../services/HomeDataService";

export default {
  name: 'Videos',
  components: {
  },
  data() {
    return {
      isDisabled: false,
      blog: "",
      blogId: this.$route.query.id,
      title: this.$route.query.title,
      imageBaseUrl: process.env.VUE_APP_UPLOAD_PATH,
      category: [],
    };
  },
  methods: {
    blogDetail() {
        let data = {
            title: this.title,
        }
        HomeDataService.blogDetail(data)
        .then((response) => {
          this.blog = response.data.data; 
          this.category = response.data.category; 
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.blogDetail();
  },
  created () {
    let currentUrl = window.location.href;
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical !== null) {
        canonical.href = currentUrl;
    }
  },
}
</script>