<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Power On Boot</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>Power On Boot</h1>
                        </div>
                        <p>Power on Boot is an application that is designed to automatically initiate the phone’s startup process when it is connected to a power source. In other words, this will help you with the shutdown management of your phone.</p>
                        <h2><b>Let's delve deeper into the functionality of the Power-On Boot App.</b></h2>
                        <p>Under normal circumstances, when a phone’s battery is discharged and the device shuts down, then you have to plug it into a power source to recharge the battery. Once the battery is charged to a certain limit, then you can <b>boot</b> up your device by pressing the power switch. Without switching on the power button, the device won’t boot up.</p>
                        <p>But with the Power on Boot application running, you do not need to press the power button once your device’s battery has charged up. The phone will automatically initiate the booting sequences once the device’s battery has obtained enough juice.</p>
                        <p>This <b>boot-on-power</b> application works only for Samsung phones, so if you are a Samsung user or your business entails Samsung phones, then the application is for you. This boot management feature ensures <b>Boot on power</b> that the device powers on seamlessly without any manual intervention whatsoever, whenever the device is plugged into a power source. This provides users with quick and convenient access to their mobile devices, streamlining their user experience, while also ensuring the device is efficiently powered. Additionally, this method also conserves energy and optimizes the device’s performance for a smoother operation.</p>
                        <h4><b>Why Choose Power-On Boot</b></h4>
                        <p>The three main reasons for utilizing this technology –</p>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Convenience::</b> Automatically initiating the booting process of the device when connected to a power source eliminates the need for users to manually power on the device. This is highly convenient as it ensures that the mobile device is ready for use as soon as it is connected to a power source.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Efficiency:</b> By automatically initiating the startup process, this feature optimizes the user experience by reducing the time it takes for the device to be operational. This is especially useful for users who rely on quick access to their devices.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Conserving Energy:</b> The ability to power off the device when disconnected from the power source helps conserve the battery life. This is useful for users who want to manage power consumption and extend the duration between charging cycles.</h6>
                        </div>
                        <h4><b>What Are the Key Features?</b></h4>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Automatic Device Startup:</b> This feature initiates the phone’s startup process automatically when connected to a power source. This way, your phone is ready for use quickly and easily without any kind of manual intervention.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Power Off on Disconnection:</b> Like automatic device startup, you can have automatic device shut down too. When the device is disconnected from the power source, then it’s shut down the device. This helps in conserving battery life and managing power consumption efficiently.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Scheduled Shut Down:</b> You can schedule the application for a <b>shutdown</b> at a specific time. This is especially helpful for users who like to have their device powered down after use, or if you are working remotely, to have the device shut down after work without having it running overnight. This helps in conserving the battery as well as the device’s longevity.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Remote Management:</b> If the device is connected to a wireless network, then a user can remotely control the bootup or <b>shutdown management</b> easily.  The user can control the application remotely and also enable a scheduled shutdown for a specific date or time.</h6>
                        </div>
                        <h4><b>Benefits of Boot on Power</b></h4>
                        <p><b>Boot on Power</b> is a handy application for businesses and individual users alike. If for instance, your organisation makes use of a large number of mobile devices as display units or kiosks, then rather than manually initiating bootup or shut down for each and every device, you can use the application.</p>
                        <p>If each device is connected to a power source, then with the <b>boot management</b> application, you can shut down all the devices at once by switching off the power source. This ensures that all devices will power down simultaneously and no device is missed.</p>
                        <p>Likewise, in the morning, when the power source is switched on, then all the devices will power on simultaneously. The devices will power on with the kiosk mode automatically without any kind of manual intervention.</p>
                        <p>The advantage here is that the battery life of these devices is conserved, and you get a longer shelf life for the battery as well as for the device. Moreover, the convenience of this application saves a lot of time and manual effort. And since the device is kept off when not in use, and because it is not plugged in during idle hours, there is no unnecessary wastage of electricity either. This is an eco-friendly way to help utilise your device without harming the environment.</p>
                        <p>If any organization has any specific requirements to be had while using <b>Power on Boot</b>, then that can be done to the tool. Any extra optimization that may be needed for scheduling, battery optimization, or any other specific scripting can be done to the application and customized accordingly.</p>
                        <h4><b>How to Login</b></h4>
                        <ol>
                            <li>After the purchase access cloud through URL: <a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a></li>
                            <li>On the login page enter email id and password. (Use the email id and password entered during the purchase)</li>
                            <li>After logging in, user will directed to the dashboard page. User can download mobile application from the 'DOWNLOAD APK' section on the page or utilize the link shared via email upon purchase.</li>
                            <li>
                                Afterward, open the Power on Boot app on your device. On the initial screen, you will find two login options.
                                <ol>
                                    <li>
                                        Enter the same email id and password used during the cloud login.
                                    </li>
                                    <li>Scan the QR code displayed in the cloud dashboard.</li>
                                    <div class="position-relative h-100 m-2">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="App Login">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="WebQrCode" alt="Login Qr Code">
                                    </div>
                                </ol>
                            </li>
                            <li>
                                Upon successful login, users will have directed to main window.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appHomeScreen" alt="App Home Screen">
                                </div>
                            </li>
                            <li>
                                On the screen user have option to ‘Enable power on boot’ and ‘Shutdown when power disconnect’
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appHomeScreenDetail" alt="App Home Screen">
                                </div>
                            </li>
                            <li>
                                Additionally, users have the option to schedule the shutdown of the device. This feature is available only when the power on boot is disabled. Users can set up scheduled shutdown times on a daily or weekly basis according to their preferences and needs.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appScheduleScreen" alt="App Home Screen">
                                </div>
                            </li>
                        </ol>
                        <h4><b>Frequently Asked Questions</b></h4>
                        <div class="accordion" v-if="tableData.length > 0" id="faqExample">
                            <div class="card" v-for="(item, index) in tableData" :key="item._id">
                                <div class="card-header p-2" :id="'heading'+index">
                                    <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
                                    {{ item.question }}
                                    </button>
                                </h5>
                                </div>
                                <div :id="'collapse'+index" :class="{ show: index==0 }" class="collapse" :aria-labelledby="'heading'+index" data-parent="#faqExample">
                                    <div class="card-body" v-html="item.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    props: {
        appLogin: {
            type: String,
            default: "./img/pob/appLogin.png",
        },
        appHomeScreen: {
            type: String,
            default: "./img/pob/appHomeScreen.png",
        },
        appHomeScreenDetail: {
            type: String,
            default: "./img/pob/appHomeScreenDetail.png",
        },
        appScheduleScreen: {
            type: String,
            default: "./img/pob/appScheduleScreen.png",
        },
        WebQrCode: {
            type: String,
            default: "./img/keymapper/WebQrCode.png",
        },
    },  
    data() {
        return {
            tableData: [
                {
                    _id: 1,
                    question: "1. How to purchase the Power on Boot app?",
                    answer:"User can purchase the app from <a href='https://www.packagedisabler.com/'>https://www.packagedisabler.com/</a>"
                },
                {
                    _id: 2,
                    question: "2. How to download the Power on Boot app?",
                    answer:"After the purchase user will receive the email with apk download link or download the app from the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>)."
                },
                {
                    _id: 3,
                    question: "3. How to login into the portal?",
                    answer:"User can login to the portal (<a href='https://app.packagedisabler.com/login'>https://app.packagedisabler.com/login</a>) with email id and password, use the same email id and password used during the purchase. "
                },
                {
                    _id: 4,
                    question: "4. How to login to the application after purchasing the app?",
                    answer:"On the login screen, either user can enter email id and password to login or scan the QR code. QR code available in the portal dashboard."
                },
                {
                    _id: 5,
                    question: "5. Did Power on Boot apps work in all Android devices?",
                    answer:"No. Bootapps only available to Samsung devices."
                },
                {
                    _id: 6,
                    question: "6. If purchased for 1 device and wanted to purchase more devices, how to purchase? ",
                    answer:"Navigate to the could portal homepage and utilize the 'BUY PLAN' option; there you will find the option to update your plan."
                },
                {
                    _id: 7,
                    question: "7. Why I am not able to login?",
                    answer:"Check the license status in the cloud portal."
                },
                {
                    _id: 8,
                    question: "8. Can I white label this app?",
                    answer:"Please contact the support team for further details."
                },
            ],
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "POBOT", recurringIdentifier: "POB" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    },  
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Enhance your device's performance with the 'Power on Boot' app – your key to optimizing startup processes. Experience seamless performance and quick access to your device by ensuring it powers on effortlessly.");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Optimize Device Startup with 'Power on Boot' App for Seamless Performance");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Battery Booster, RAM cleaner, battery performance, Power Boosters, battery booster software");*/
    },
}
</script>