<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div :style="{ display: 'flex', 'justify-content': 'center' }">
                            <a  :href="'/payment?id='+priceDetail.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(priceDetail.subscriptionPrice.amount) }})</a>
                            <a :href="'/payment?id='+priceDetail.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(priceDetail.oneTimePrice.amount) }})</a>
                        </div>
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">URL Blocker</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>URL Blocker</h1>
                        </div>
                        <p>URL blocker application designed to restrict access to particular web addresses or URLs. This app widely utilized for diverse purposes including parental control, <a href="javascript:void(0)" @click="$router.push({ path: '/web-filtering'})">Content Filtering</a>, and network security. URL blocker typically works by maintaining a list of blocked URLs. When a user tries to reach a URL listed on the blocked list, the URL blocker steps in and denies access.</p>
                        <h2><b>Why Choose URL BLOCKER?</b></h2>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Secure:</b> The URL Blocker application operates using a Local VPN approach, ensuring that no data is transmitted beyond the device.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Privacy Assurance:</b> Ensures the application operates without extracting or transmitting user data outside the device, prioritizing privacy.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Compatibility with all browsers:</b> The URL Blocker application is designed to function seamlessly across all Android-supported browsers, including Firefox, Chrome, Internet, and more.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Versatile Usage:</b> Suitable for both individual and enterprise use, catering to diverse user needs.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>User-Friendly Interface:</b >Features a simple navigation and configuration, making it accessible for users of all levels.</h6>
                        </div>
                        <h3><b>Key Features of URL FILTER Application</b></h3>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Content Filtering:</b> URL blocker used for <a href="javascript:void(0)" @click="$router.push({ path: '/web-filtering'})">Content Filtering</a>, allowing users to control access to specific websites or categories of content. This can be particularly useful for parents who want to restrict their children's access to inappropriate or harmful material.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>User-Customizable Blocked lists:</b> URL blocker have the option for users to create and maintain their own personalized blocked lists.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Cloud Control:</b> Cloud portal is available for device, blocked list and license management.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Security:</b> URL blocker are employed as a security measure to prevent access to websites known for phishing, malware, or other security threats. Blocking access to malicious URLs helps protect users from potential cybersecurity risks.</h6>
                            <h6 class="mb-3"><i class="text-primary me-3"></i><b>Productivity Enhancement:</b> In a workplace or educational setting, URL blockers can be used to enhance productivity by restricting access to distracting or non-work-related websites. This helps maintain focus and ensures that employees or students use their time more effectively.</h6>
                        </div>
                        <h4><b>How to Login</b></h4>
                        <ol>
                            <li>After the purchase access cloud through URL: <a href="https://app.packagedisabler.com/">https://app.packagedisabler.com/</a></li>
                            <li>On the login page enter email id and password. (Use the email id and password entered during the purchase)</li>
                            <li>After logging in, user will directed to the dashboard page. User can download mobile application from the 'DOWNLOAD APK' section on the page or utilize the link shared via email upon purchase.</li>
                            <li>
                                Next launch the URL Blocker app in the device. In the initial screen user have two option to login.
                                <ol>
                                    <li>
                                        Enter the same email id and password used during the cloud login.
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Url Blocker Login">
                                        </div>
                                    </li>
                                    <li>Scan the QR code displayed in the cloud dashboard.</li>
                                    <div class="position-relative h-100">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="appQrCodeLogin" alt="Url Blocket QR Code Login">
                                        <img class="img-fluid" data-wow-delay="0.9s" :src="WebQrCode" alt="Url Blocker Login QR Code">
                                    </div>
                                </ol>
                            </li>
                            <li>
                                Upon successful login, users will have directed to initial window.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appHomeScreen" alt="Url Blocker Home Page">
                                </div>
                                <p>For detailed guidance on accessing the cloud and the application, please refer to the Cloud Access Guide and Application Access Guide sections respectively.</p>
                            </li>
                        </ol>
                        <h4><b>Cloud access guide</b></h4>
                        <ol>
                            <li>Purchase URL Blocker application from the package disabler website. Then login to the cloud portal <a href="https://app.packagedisabler.com/">(https://app.packagedisabler.com/)</a> with your credentials to manage the devices.</li>
                            <li>Select the Devices menu on the left side panel
                                <ol>
                                    <li>In Devices menu window user can see the list of available devices. Select the respective serial number to access the device</li>
                                    <li>To add new device please follow the steps Mobile Application Access Guide.
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceList" alt="Device List">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                            <li>After selecting the serial number, a new window will appear which consist 3 tabs - URL, File and Devices
                                <ol>
                                    <li><b>URL:</b> In this tab you can add/delete URLs to be blocked/unblocked one by one.
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webUrlTab" alt="Url Tab">
                                        </div>
                                    </li>
                                    <li><b>FILE:</b> In this tab you can update the file url (user can pre-create a file with the list of URLs to be blocked and save it in the storage)
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webFileTab" alt="File Tab">
                                        </div>
                                    </li>
                                    <li><b>DEVICES:</b> User can view the device details.
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="webDeviceTab" alt="Device Detail">
                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <h4><b>Mobile Application Access Guide</b></h4>
                        <p>Download URL Blocker from the purchase email or from the cloud portal and install it.</p>
                        <ol>
                            <li>Launch the URL Blocker app and press the scan icon to scan the QRCODE or enter the user id and password to login.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appLoginFullScreen" alt="Login">
                                </div>
                            </li>
                            <li>
                                Upon login user could see one file icon on top right corner and plus icon on down right corner.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appHomeScreenSmall" alt="Home Screen">
                                </div>
                                <ol>
                                    <li>
                                        File icon - User can upload the pre created file with list of blocked URLs
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appFileTab" alt="Application File Tab">
                                        </div>  
                                    </li>
                                    <li>
                                        Plus icon – Upon selecting + icon a new pop up window will appear to enter the url, users can add URLs one by one.
                                        <div class="position-relative h-100 m-2">
                                            <img class="img-fluid" data-wow-delay="0.9s" :src="appUrltab" alt="Application Url Tab">
                                        </div>  
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Once all URLs added close the popup window. On the main screen user can see the blocked URL's list
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appUrlList" alt="Url List">
                                </div>  
                            </li>
                            <li>
                                Follows that click the START button to URL blocker to start blocking the URLs. To end blocking the URL's click the stop button.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appStartBtn" alt="Start Button">
                                </div>  
                            </li>
                            <li>
                                User can delete each URL from the by clicking the delete button besides the URL.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appUrlDel" alt="Url Delete">
                                </div>  
                            </li>
                            <li>
                                To implement parental control, add the URLs as mentioned above. Afterward, click on the settings icon located in the top-right corner. Here, users have the option to set a password for the URL blocker application ensuring restricted access to the app.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appParentControl" alt="Parent Control">
                                </div>  
                            </li>
                        </ol>
                        <h4><b>Frequently Asked Questions</b></h4>
                        <div class="accordion" v-if="tableData.length > 0" id="faqExample">
                            <div class="card" v-for="(item, index) in tableData" :key="item._id">
                                <div class="card-header p-2" :id="'heading'+index">
                                    <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
                                    {{ item.question }}
                                    </button>
                                </h5>
                                </div>
                                <div :id="'collapse'+index" :class="{ show: index==0 }" class="collapse" :aria-labelledby="'heading'+index" data-parent="#faqExample">
                                    <div class="card-body" v-html="item.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
import PaymentService from "../services/PaymentService";
export default {
    props: {
        appLogin: {
            type: String,
            default: "./img/urlblocker/AppLogin.png",
        },
        appQrCodeLogin: {
            type: String,
            default: "./img/urlblocker/AppQrCodeLogin.png",
        },
        WebQrCode: {
            type: String,
            default: "./img/urlblocker/WebQrCode.png",
        },
        appHomeScreen: {
            type: String,
            default: "./img/urlblocker/AppHomeScreen.png",
        },
        webDeviceList: {
            type: String,
            default: "./img/urlblocker/WebDeviceList.png",
        },
        webFileTab: {
            type: String,
            default: "./img/urlblocker/WebFileTab.png",
        },
        webUrlTab: {
            type: String,
            default: "./img/urlblocker/WebUrlTab.png",
        },
        webDeviceTab: {
            type: String,
            default: "./img/urlblocker/WebDeviceTab.png",
        },
        appLoginFullScreen: {
            type: String,
            default: "./img/urlblocker/AppLoginFullScreen.png",
        },
        appHomeScreenSmall: {
            type: String,
            default: "./img/urlblocker/AppHomeScreenSmall.png",
        },
        appFileTab: {
            type: String,
            default: "./img/urlblocker/AppFileTab.png",
        },
        appUrltab: {
            type: String,
            default: "./img/urlblocker/AppUrltab.png",
        },
        appUrlList: {
            type: String,
            default: "./img/urlblocker/appUrlList.png",
        },
        appStartBtn: {
            type: String,
            default: "./img/urlblocker/AppStartBtn.png",
        },
        appUrlDel: {
            type: String,
            default: "./img/urlblocker/AppUrlDel.png",
        },
        appParentControl: {
            type: String,
            default: "./img/urlblocker/AppParentControl.png",
        }
    },  
    data() {
        return {
            tableData: [
                {
                    _id: 1,
                    question: "1. Why my URL is not blocked?",
                    answer:"Check key icon in the status bar. To enable URL blocking, users must grant VPN permission. When accessing the application for the first time, users will be prompted to grant VPN permission. 	Check the spelling of the URLs added in the list"
                },
                {
                    _id: 2,
                    question: "2. What is the key symbol on status bar? ",
                    answer:"Key symbol indicates that your device is connected to VPN."
                },
                {
                    _id: 3,
                    question: "3. Why I have to give VPN permission?",
                    answer:"The URL Blocker application operates using a Local VPN approach, hence users are required to grant VPN permission upon their first time access."
                },
                {
                    _id: 4,
                    question: "4. Is my browsing history saved in cloud? ",
                    answer:"No. A URL blocker doesn't retain any browsing history since it doesn't transfer data outside your device."
                },
                {
                    _id: 5,
                    question: "5. What is the data protection in URL Blocker?",
                    answer:"All data processed locally, no data transmitted outside the device."
                },
                {
                    _id: 6,
                    question: "6. Will the URL blocker work across browsers?",
                    answer:"Yes. URL blocked across all Android-supported browsers, including Firefox, Chrome, Internet, and more."
                },
                {
                    _id: 7,
                    question: "7. Will this block URL access from apps?",
                    answer:"Yes, It will. The only exception is if the apps are encrypted."
                },
                {
                    _id: 8,
                    question: "8. How can I block a large number of URLs at once?",
                    answer:"Users can block multiple URLs simultaneously by preparing a file containing all the URLs (separated by commas) and uploading it in the file section of the app."
                },
                {
                    _id: 9,
                    question: "9. How can I use URL Blocker for parental control?",
                    answer:"Update the blocked list as usual, and in the application settings, users can set a password to restrict access. This password protection adds an extra layer of control, allowing parents to manage and monitor website access for a safer online "
                },
            ],
            priceDetail: "",
        }
    },
    methods: {
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        async productPriceDetail() {
            let data = { onetimeIdentifier: "UBOT", recurringIdentifier: "UB" }
            await PaymentService.productPriceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data; 
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.productPriceDetail();
    },  
    created () {
        let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Effortlessly manage and control your device's functionality with a URL blocker from Package Disabler. Gain the ability to block unwanted URLs and enhance security and productivity on your device. Explore now!");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Get the most out of URL Blocker with Package Disabler");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "URL Blocker, package Disabler,");
    },
}
</script>