import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PowerCleaner from '../views/PowerCleaner.vue'
import PowerChat from '../views/PowerChat.vue'
import UrlBlocker from '../views/UrlBlocker.vue'
import KeyMapper from '../views/KeyMapper.vue'
import PowerOnBoot from '../views/PowerOnBoot.vue'
import PackageDisabler from '../views/PackageDisabler.vue'
import OwnerApp from '../views/OwnerApp.vue'
import Videos from '../views/Videos.vue'
import Faq from '../views/Faq.vue'
import Blogs from '../views/Blogs.vue'
import Blog from '../views/Blog.vue'
import Contact from '../views/Contact.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import PackageInfo from '../views/PackageInfo.vue'
import PaymentPage from '../views/PaymentPage.vue'
import PaymentSuccess from '../views/PaymentSuccess'

import BatteryBooster from '../views/Solutions/BatteryBooster'
import BrowserHistoryAndCookiesRemover from '../views/Solutions/BrowserHistoryAndCookiesRemover'
import HarwareKeyDisabler from '../views/Solutions/HarwareKeyDisabler'
import MobileApplicationManagement from '../views/Solutions/MobileApplicationManagement'
import QuickBloatDetox from '../views/Solutions/QuickBloatDetox'
import WebFiltering from '../views/Solutions/WebFiltering'
import SureLock from '../views/Solutions/SureLock'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home - Package Disabler' }
  },
  {
    path: '/power-cleaner',
    name: 'PowerCleaner',
    component: PowerCleaner,
    meta: { title: 'Power Cleaner - Package Disabler' }
  },
  {
    path: '/power-chat',
    name: 'Power Chat',
    component: PowerChat,
    meta: { title: 'Power Chat - Package Disabler' }
  },
  {
    path: '/url-blocker',
    name: 'Url Blocker',
    component: UrlBlocker,
    meta: { title: 'Url Blocker - Package Disabler' }
  },
  {
    path: '/key-mapper',
    name: 'Key Mapper',
    component: KeyMapper,
    meta: { title: 'Key Mapper - Package Disabler' }
  },
  {
    path: '/package-disabler',
    name: 'Package Disabler',
    component: PackageDisabler,
    meta: { title: 'About - Package Disabler' }
  },
  {
    path: '/power-on-boot',
    name: 'Power On Boot',
    component: PowerOnBoot,
    meta: { title: 'Power On Boot - Package Disabler' }
  },
  {
    path: '/package-info',
    name: 'PackageInfo',
    component: PackageInfo,
    meta: { title: 'Package Info - Package Disabler' }
  },
  {
    path: '/owner-app',
    name: 'OwnerApp',
    component: OwnerApp,
    meta: { title: 'Owner App - Package Disabler' }
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    meta: { title: 'Videos - Package Disabler' }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: { title: 'FAQ - Package Disabler' }
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: Blogs,
    meta: { title: 'Blogs - Package Disabler' }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: { title: 'Blog Detail - Package Disabler' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact Us - Package Disabler' }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy - Package Disabler' }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentPage,
    meta: { title: 'Payment - Package Disabler' }
  },
  {
    path: '/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/battery-booster',
    name: 'Battery Booster',
    component: BatteryBooster,
    meta: { title: 'Battery Booster - Package Disabler' }
  },
  {
    path: '/browser-history-and-cookies-remover',
    name: 'Browser History And Cookies Remover',
    component: BrowserHistoryAndCookiesRemover,
    meta: { title: 'Browser History And Cookies Remover - Package Disabler' }
  },
  {
    path: '/hardware-key-disabler',
    name: 'Harware Key Disabler',
    component: HarwareKeyDisabler,
    meta: { title: 'Harware Key Disabler - Package Disabler' }
  },
  {
    path: '/mobile-application-management',
    name: 'Mobile Application Management',
    component: MobileApplicationManagement,
    meta: { title: 'Mobile Application Management - Package Disabler' }
  },
  {
    path: '/quick-bloat-detox',
    name: 'Quick Bloat Detox',
    component: QuickBloatDetox,
    meta: { title: 'Quick Bloat Detox - Package Disabler' }
  },
  {
    path: '/web-filtering',
    name: 'Web Filtering',
    component: WebFiltering,
    meta: { title: 'Web Filtering - Package Disabler' }
  },
  {
    path: '/sure-lock',
    name: 'Sure Lock',
    component: SureLock,
    meta: { title: 'Sure Lock - Package Disabler' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})
router.afterEach((to, from) => {
  document.title = to.meta.title;
});
export default router
