import http from "../http-common";

class PaymentService {
    priceList() {
        return http.get("payments/plan/list");
    }
    validateEmail(data) {
        return http.post("payments/validate/email", data);
    }
    makePayment(data) {
        return http.post("payments/purchase", data);
    }
    priceDetail(data) {
        return http.get("payments/plan/detail", { params: data });
    }
    applyCoupon(data) {
        return http.post("payments/coupon/apply", data);
    }
    updateSubscription(data) {
        return http.post("payments/subscription/update-without-auth", data);
    }
    productPriceDetail(data) {
        return http.get("payments/product/price/detail", { params: data });
    }
    createPaymentIntent(data) {
        return http.post("payments/paymentIntent/create", data);
    }
    logPaymentError(data) {
        return http.post("payments/error", data);
    }
}

export default new PaymentService();