<template>
    <div>
        <!-- Footer Start -->
        <div class="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row gx-5">
                    <div class="col-lg-4 col-md-6 footer-about">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                            <a href="index.html" class="navbar-brand">
                                <img :src="logo" alt="Package Disabler Logo" />
                            </a>
                            <p class="mt-3 mb-4">Package Disabler : The only NON-root solution that let’s you disable any unwanted packages that come pre-installed / installed with your phone / tablet.</p>
                            <div class="input-group">
                                <input type="text"  v-model="email" class="form-control border-white p-3" placeholder="Your Email">
                                <button class="btn btn-dark" :disabled="isDisabled" @click="subscribeNewsletter()">Subscribe</button>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <div class="row gx-5">
                            <div class="col-lg-6 col-md-12 pt-5 mb-5">
                                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 class="text-light mb-0">Get In Touch</h3>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-geo-alt text-primary me-2"></i>
                                    <p class="mb-0">London, England, WC2A 2JR</p>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-envelope-open text-primary me-2"></i>
                                    <p class="mb-0">support@packagedisabler.com</p>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-telephone text-primary me-2"></i>
                                    <p class="mb-0">+442031294012</p>
                                </div>
                                <div class="d-flex mt-4">
                                    <a class="btn btn-primary btn-square me-2" href="https://twitter.com/DisablerPackage" target="_blank"><i class="fab fa-twitter fw-normal"></i></a>
                                    <a class="btn btn-primary btn-square me-2" href="https://www.youtube.com/@packagedisabler7733" target="_blank"><i class="fab fa-youtube fw-normal"></i></a>
                                    <a class="btn btn-primary btn-square me-2" href="https://www.linkedin.com/company/packagedisabler/" target="_blank"><i class="fab fa-linkedin-in fw-normal"></i></a>
                                    <a class="btn btn-primary btn-square me-2" href="https://www.facebook.com/PackageDisabler/" target="_blank"><i class="fab fa-facebook-f fw-normal"></i></a>
                                    <a class="btn btn-primary btn-square" href="https://www.instagram.com/packagedisabler2023/" target="_blank"><i class="fab fa-instagram fw-normal"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 class="text-light mb-0">Quick Links</h3>
                                </div>
                                <div class="link-animated d-flex flex-column justify-content-start">
                                    <a class="text-light mb-2" href="javascript:void(0)" @click="$router.push({ path: '/'})"><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                    <a class="text-light mb-2" href="javascript:void(0)" @click="$router.push({ path: '/privacy-policy'})"><i class="bi bi-arrow-right text-primary me-2"></i>Privacy Policy</a>
                                    <a class="text-light mb-2" href="javascript:void(0)" @click="$router.push({ path: '/videos'})"><i class="bi bi-arrow-right text-primary me-2"></i>Videos</a>
                                    <a class="text-light mb-2" href="javascript:void(0)" @click="$router.push({ path: '/faq'})"><i class="bi bi-arrow-right text-primary me-2"></i>FAQ</a>
                                    <a class="text-light mb-2" href="javascript:void(0)" @click="$router.push({ path: '/contact'})"><i class="bi bi-arrow-right text-primary me-2"></i>Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid text-white" style="background: #061429;">
            <div class="container text-center">
                <div class="row justify-content-end">
                    <div class="col-lg-8 col-md-6">
                        <div class="d-flex align-items-center justify-content-center" style="height: 75px;">
                            <p class="mb-0">&copy; <a class="text-white border-bottom" href="#">Package Disabler</a>. All Rights Reserved. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HomeDataService from "../services/HomeDataService";
import {useToast} from 'vue-toast-notification';
const $toast = useToast();
export default {
  name: 'Footer',
  props: {
    logo: {
      type: String,
      default: "./img/logo-light.png",
    },
    whatsappLogo: {
      type: String,
      default: "./img/whatsapp-50.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      email: "",
    };
  },
  methods: {
    subscribeNewsletter() {
        this.isDisabled = true;
        let data =  { email: this.email }
        HomeDataService.subscribeNewsletter(data)
        .then((response) => {
            this.email = "";
            this.isDisabled = false;
            $toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            $toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    
  },
}
</script>