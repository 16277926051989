<template>
    <div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Complete Info For Packages And Hardware</h5>
                        </div>
                        <p>“Package Info” app is developed to inspect full details of apps / packages in your device. It lists all apps / packages in your device with colour codes, depending on its category i.e. The package belongs to system, installed, bloatware or disabled etc… Category marked with different colours. To get full details of an app / package you have to just click on any package in the list and it will show you its services permissions, activities, receivers, content providers, all libraries, signature and many more informations.</p>
                        <p><strong>It also gives you full details of hardware like device details, software version, display info, network, battery, storage, sensors, processor CPU etc..</strong></p>
                    </div>
                    <div class="col-lg-5" style="min-height: 500px;">
                        <div>
                            <img data-wow-delay="0.9s" src="https://www.packagedisabler.com/images/saas/2.png" style="object-fit: cover;width: 100%" alt="Package Info">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->

        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">What Problem Does It Solves?</h5>
                        </div>
                        <p>Mobile devices are a part of your life and it is with you all the time, so it is very important to understand what is really inside in it. Application you install from play store and outside may look secure but many of them got many security threats. Also there are many reports OEM, Operators include malware in the device to steal your personal data. Package Info app helps you to inspect what packages are inside your devices and lists most problematic apps, with colour code. Our aim is to make the user aware of this type of stealth attacks put inside your devices.</p>
                    </div>
                    <div class="col-lg-6">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">What Is Unique About The App?</h5>
                        </div>
                        <p>We have added many scanning feature to list suspicious / dangerous apps, start-up apps, apps installed outside play etc. Also we display signature of the apps so that if any tampered apps can be detected easily. It also list full details of hardware and system information of your device.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        <br>

        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Why Should You Download The App?</h5>
                        </div>
                        <p>Make your device secure and understand what is inside your device which you are carrying all the time. Also get details of the hardware inside your device. Scan for suspicious apps and list it to you. App is free and no ads, please help by reporting packages you think are dangerous to android users.</p>
                    </div>
                    <div class="col-lg-5" style="min-height: 500px;">
                        <div>
                            <img data-wow-delay="0.9s" src="https://www.packagedisabler.com/images/saas/3.png" alt="Package Info">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->

        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">What Are The Best Features?</h5>
                        </div>
                        <ul>
                            <li><h5>Disabled Apps (Menu-&gt;Show Disabled)</h5><p>Applications which are inactive in your device, such apps cannot do any harm to your system. Disabled app will not use any of your memory (RAM) but it can take storage space of your device.</p></li>
                            <li><h5>Installed Apps (Menu-&gt;Show Installed)</h5><p>Applications which are installed from play store or from many other sources.</p></li>
                            <li><h5>System Apps (Menu-&gt;Show System)</h5><p>Applications pre-installed on the system by Android (Google) or by OEMs, also apps signed with system keys are also considered as system apps. System apps can have system permissions, which are normally unattainable to normal play store apps.</p></li>
                            <li><h5>Start-up Apps (Menu-&gt;Start-up Apps)</h5><p>Applications which start automatically on system (device) boot, if you have many such apps your boot process may slow-down and also overall device performance.</p></li>
                            <li><h5>Dangerous Apps (Menu-&gt;Scan For Dangerous Apps)</h5><p>Applications' permission has the ability to access some potentially sensitive information or perform actions on the device. Most apps in the play store got few dangerous permission, so it is very difficult to identify whether or not the app is dangerous to use. Please help us to identify such dangerous app by reporting the details of it to us.</p></li>
                            <li><h5>Suspicious Apps (Menu-&gt;Scan For Suspicious Apps)</h5><p>Normal applications cannot have system permission, most such applications are by Android system / OEMs pre installed apps. By scanning we understood that some of the application in the play store / outside playstore have these system only permissions. Examples of some of these powerful permissions that use the signatureOrSystem protection level are “INSTALL_PACKAGES”, “INJECT_EVENTS”, “READ_FRAME_BUFFER” etc … Exploiting an application that holds one of these permissions could allow an attacker a great degree of control over the device and therefore over its data. Please scan your phone for signatureOrSystem permission and then remove if possible. Even you know the application completely, still there is a chance other normal apps in your system may use this app to do proxy attacks.</p></li>
                            <li><h5>Large Heap (Menu-&gt;Apps Using Large Heap)</h5><p>When out of memory happens to app, developer may tend to quick fix it by declaring android:largeHeap="true", in the manifest file. But this will have side effects on other running apps and overall user experience. When app with large heap is loaded to memory android system will terminate other apps’ processes to free up system RAM which will have direct impact on multitasking and response and you feel your phone is sluggish. Android don’t recommend this kind of quick fix rather to do better memory management in the app. “Most apps should not need this and should instead focus on reducing their overall memory usage for improved performance.” This menu option is to find all apps using huge heap in the apps' manifest, be careful on such apps.</p></li>
                            <li><h5>Apps Contain Native Library (Menu-&gt;Apps With Native Libs)</h5><p>Applications that are using c++ library built using NDK, this menu option list all libraries with its size.</p></li>
                            <li><h5>Apps Installed Outside Play (Menu-&gt;Apps Outside Play)</h5><p>Applications that are installed outside play store / or other play stores. Most malwares or securities are coming from apps installed outside play store. So we strongly recommend removing if you are not sure or not from reputed developers.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        <br>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Where’s The Challenge?</h5>
                        </div>
                        <p>There are millions of app in the play store and outside, and it is impossible the user to check whether the apps got any security threat. Google scanning etc … are doing great job but it is not handling the attacks from with in (see the blog in details http://www.packagedisabler.com/the-enemy-within-bloatware/). This app gives you an X-ray view of the package / apps inside your device.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        <br>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Package Details</h5>
                        </div>
                        <p>If you click on a package from the list you will get full details of that package. The details include Package name, size, package path, installed date, version code, type, signature etc … It also shows full details of elements used in the app, see below list</p>
                        <ul>
                            <li><h5>Permission Details</h5><p>List all permission with colour code, to get the details of the colour code just double click inside permission list</p></li>
                            <li><h5>Activity Details</h5><p>Lists all exported and non exported activities used in the app, exported / non exported activities are listed with different colour code</p></li>
                            <li><h5>Service Details</h5><p>Lists all exported and non exported services used in the app, exported / non exported services are listed with different colour code</p></li>
                            <li><h5>Receivers Details</h5><p>Lists all exported and non exported receivers used in the app, exported / non exported receivers are listed with different colour code</p></li>
                            <li><h5>Shared Libraries</h5><p>Lists all libraries used in the app, with size and path to library. It shows both native and non native libs</p></li>
                            <li><h5>Content Providers</h5><p>Lists all exported and non exported content providers used in the app, exported / non exported content providers are listed with different colour code</p></li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Button Interface</h5>
                        </div>
                        <ul>
                            <li><h5>Settings</h5><p>Take you to android setting of the package you selected</p></li>
                            <li><h5>Google it</h5><p>If you are not sure about the package then you could search on Google by just clicking this button</p></li>
                            <li><h5>Run</h5><p>To start app, if app is not runnable then this button will be disabled</p></li>
                            <li><h5>Uninstall</h5><p>Uninstall current app, if app is system app then this button will be disabled</p></li>
                            <li><h5>Report</h5><p>If you like to join fight against bloatware please use this button to report to us about it</p></li>
                            <li><h5>Share</h5><p>If you like to copy the details of the package to clipboard or email click this button</p></li>
                            <li><h5>Show List</h5><p>Show the list of app signed with same app key</p></li>                    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>