<template>
    <div>
    <!-- Topbar Start -->
    <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0">
            <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                <div class="d-inline-flex align-items-center" style="height: 45px;">
                    <small class="me-3 text-light"><i class="fa fa-map-marker-alt me-2"></i>London, UK</small>
                    <small class="me-3 text-light"><i class="fa fa-phone-alt me-2"></i><a href="tel:+442031294012">+442031294012</a></small>
                    <small class="text-light"><i class="fa fa-envelope-open me-2"></i><a href="mailto:support@packagedisabler.com">support@packagedisabler.com</a></small>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-inline-flex align-items-center" style="height: 45px;">
                    <a class="btn btn-sm btn-outline-light" target="_blank" href="https://cloud.packagedisabler.com/pmc/login">Old Login</a>&nbsp;
                    <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://twitter.com/DisablerPackage"><i class="fab fa-twitter fw-normal"></i></a>
                    <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://www.linkedin.com/company/packagedisabler/"><i class="fab fa-linkedin-in fw-normal"></i></a>
                    <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://www.facebook.com/PackageDisabler/"><i class="fab fa-facebook-f fw-normal"></i></a>
                    <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://www.instagram.com/packagedisabler2023/"><i class="fab fa-instagram fw-normal"></i></a>
                    <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" target="_blank" href="https://www.youtube.com/@packagedisabler7733"><i class="fab fa-youtube fw-normal"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">
        <!-- Navbar Start -->
        <div class="container-fluid position-relative p-0">
            <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                <a href="javascript:void(0)" @click="$router.push({ path: '/'})" style="display: contents;" class="navbar-brand p-0">
                    <img :src="logo" style="height:50px;" alt="Package Disabler Logo"/> 
                    <h2 class="m-0 d-none d-lg-block">Package Disabler</h2>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="fa fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav ms-auto py-0">
                        <a href="javascript:void(0)" @click="$router.push({ path: '/'})" class="nav-item nav-link" :class="{ active: $route.name == 'home' }">Home</a>
                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</a>
                            <div class="dropdown-menu m-0">
                                <a href="javascript:void(0)" @click="$router.push({ path: '/package-disabler'})" class="dropdown-item" :class="{ active: $route.name == 'Package Disabler' }">Package Disabler</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/power-cleaner'})" class="dropdown-item" :class="{ active: $route.name == 'PowerCleaner' }">Power Cleaner</a>
                                <!--<a href="javascript:void(0)" @click="$router.push({ path: '/package-info'})" class="nav-item nav-link" :class="{ active: $route.name == 'PackageInfo' }">Package Info</a>-->
                                <a href="javascript:void(0)" @click="$router.push({ path: '/owner-app'})" class="dropdown-item" :class="{ active: $route.name == 'OwnerApp' }">Owner App</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/power-chat'})" class="dropdown-item" :class="{ active: $route.name == 'Power Chat' }">Power Chat</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/url-blocker'})" class="dropdown-item" :class="{ active: $route.name == 'Url Blocker' }">Url Blocker</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/key-mapper'})" class="dropdown-item" :class="{ active: $route.name == 'Key Mapper' }">Key Mapper</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/power-on-boot'})" class="dropdown-item" :class="{ active: $route.name == 'Power On Boot' }">Power On Boot</a>
                            </div>
                        </div>
                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Solution</a>
                            <div class="dropdown-menu m-0">
                                <a href="javascript:void(0)" @click="$router.push({ path: '/battery-booster'})" class="dropdown-item" :class="{ active: $route.name == 'Battery Booster' }">Battery Booster</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/browser-history-and-cookies-remover'})" class="dropdown-item" :class="{ active: $route.name == 'Browser History And Cookies Remover' }">Browser History And Cookies Remover</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/hardware-key-disabler'})" class="dropdown-item" :class="{ active: $route.name == 'Harware Key Disabler' }">Hardware Key Disabler</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/quick-bloat-detox'})" class="dropdown-item" :class="{ active: $route.name == 'Quick Bloat Detox' }">Quick Bloat Detox</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/web-filtering'})" class="dropdown-item" :class="{ active: $route.name == 'Web Filtering' }">Web Filtering</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/mobile-application-management'})" class="dropdown-item" :class="{ active: $route.name == 'Mobile Application Management' }">Mobile Application Management</a>
                                <a href="javascript:void(0)" @click="$router.push({ path: '/sure-lock'})" class="dropdown-item" :class="{ active: $route.name == 'Sure Lock' }">Sure Lock</a>
                            </div>
                        </div>
                        <a :href="'javascript:void(0)'" @click="$router.push({ path: '/blogs'})" class="nav-item nav-link" :class="{ active: $route.name == 'Blogs' }">Blogs</a>
                        <a :href="'javascript:void(0)'" @click="$router.push({ path: '/videos'})" class="nav-item nav-link" :class="{ active: $route.name == 'Videos' }">Videos</a>
                        <a href="javascript:void(0)" @click="$router.push({ path: '/faq'})" class="nav-item nav-link" :class="{ active: $route.name == 'Faq' }">FAQ</a>
                        <a href="javascript:void(0)" @click="$router.push({ path: '/contact'})" class="nav-item nav-link" :class="{ active: $route.name == 'Contact' }">Contact</a>
                    </div>
                    <div :style="{  'display': 'flex'}">
                        <a href="https://app.packagedisabler.com/login" target="_blank" class="btn btn-primary py-2 px-4 ms-3">Login</a>
                        <a href="https://docs.packagedisabler.com/home" target="_blank" class="btn btn-primary py-2 px-4 ms-3">Documentation</a>
                    </div>
                </div>
            </nav>
            <div class="container-fluid bg-primary py-5 bg-header" style="margin-bottom: 90px;">
                <!--<div class="row py-5">
                    <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                        <h1 class="display-4 text-white animated zoomIn">Purchase Process</h1>
                        <a href="" class="h5 text-white">When package disabler is purchased from the website you will get a link to download the app along with a login detail that you provided during the purchase.</a>
                    </div>
                </div>-->
            </div>
        </div>
        <!-- Navbar End -->
    </div>
    <div class="sticky-container">
        <ul class="sticky">
            <li>
                <img :src="whatsappLogo" width="50" height="50" alt="Package Disabler Whatsapp Number">
                <p><a href="https://api.whatsapp.com/send/?phone=447449294718&text=Please contact us on WhatsApp for a faster reply." target="_blank">Contact us <br>on Whatsapp</a></p>
            </li>
        </ul>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    logo: {
      type: String,
      default: "./img/logo-light.png",
    },
    whatsappLogo: {
      type: String,
      default: "./img/whatsapp-50.png",
    },
  },
  data() {
    return {
        currentRoute: this.$route.name,
    }
  }
}
</script>