<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12 text-center">
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Blogs</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Start -->
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <!-- Blog list Start -->
                <div class="col-lg-8">
                    <div class="row g-5">
                        <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s" v-for="blog in tableData" :key="blog._id">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                <div class="blog-img position-relative overflow-hidden">
                                    <div class="blog-img">
                                        <img class="img-fluid" :src="`${this.imageBaseUrl}/blog/${blog.image}`" alt="Blog Image">
                                    </div>
                                    
                                    <div class="child">
                                        <a class="text-white rounded-end py-2 px-4">{{ blog.category }}</a>
		                            </div>
                                </div>
                                <div class="p-4">
                                    <div class="d-flex mb-3">
                                        <small class="me-3"><i class="far fa-user text-primary me-2"></i>{{ blog.author }}</small>
                                        <small><i class="far fa-calendar-alt text-primary me-2"></i>{{ HumanReadableDateFormat(blog.created_at, true) }}</small>
                                    </div>
                                    <h5 class="mb-1">{{ blog.title }}</h5>
                                    <p>{{ blog.shortDescription.substring(0,100)+"..." }}</p>
                                    <a class="text-uppercase" href="javascript:void(0)" @click="$router.push({ path: '/blog', query: { title: blog.title }})">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <span v-if="tableData.length == 0">No blog found.</span>
                    </div>
                </div>
                <!-- Blog list End -->
    
                <!-- Sidebar Start -->
                <div class="col-lg-4">                    
                    <!-- Category Start -->
                    <div class="mb-5 wow slideInUp" data-wow-delay="0.1s" v-if="category.length > 0">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="mb-0">Categories</h3>
                        </div>
                        <div class="link-animated d-flex flex-column justify-content-start">
                            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" v-for="cat in category" :key="cat._id" href="javascript:void(0)" @click="blogList(cat._id)">{{ cat._id }}</a>
                        </div>
                    </div>
                    <!-- Category End -->
    
                    <!-- Tags Start 
                    <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="mb-0">Tag Cloud</h3>
                        </div>
                        <div class="d-flex flex-wrap m-n1">
                            <a href="" class="btn btn-light m-1">Design</a>
                            <a href="" class="btn btn-light m-1">Development</a>
                            <a href="" class="btn btn-light m-1">Marketing</a>
                            <a href="" class="btn btn-light m-1">SEO</a>
                            <a href="" class="btn btn-light m-1">Writing</a>
                            <a href="" class="btn btn-light m-1">Consulting</a>
                            <a href="" class="btn btn-light m-1">Design</a>
                            <a href="" class="btn btn-light m-1">Development</a>
                            <a href="" class="btn btn-light m-1">Marketing</a>
                            <a href="" class="btn btn-light m-1">SEO</a>
                            <a href="" class="btn btn-light m-1">Writing</a>
                            <a href="" class="btn btn-light m-1">Consulting</a>
                        </div>
                    </div>
                     Tags End -->
                </div>
                <!-- Sidebar End -->
            </div>
        </div>
    </div>
    <!-- Blog End -->
    </div>
</template>
<script>
// @ is an alias to /src
import HomeDataService from "../services/HomeDataService";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

export default {
  name: 'Videos',
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      imageBaseUrl: process.env.VUE_APP_UPLOAD_PATH,
      category: [],
    };
  },
  methods: {
    blogList(category = null) {
        let data = {
            category: category,
        }
        HomeDataService.blogList(data)
        .then((response) => {
            $toast.success(response.data.respones_msg);
            this.tableData = response.data.data; 
            this.category = response.data.category; 
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    HumanReadableDateFormat(date, onlyDate = false) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        let dt = new Date(date);
        if (dt == "Invalid Date") {
            return "-";
        }
        let day = ("0" + dt.getDate()).slice(-2);
        let month = dt.getMonth();
        let year = dt.getFullYear();
        let hour = ("0" + dt.getHours()).slice(-2);
        let minute = ("0" + dt.getMinutes()).slice(-2);
        let seconds = ("0" + dt.getSeconds()).slice(-2);
        if (onlyDate) {
            return day + " " + monthNames[month] + " " + year;
        } else {
            return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
        }
    },
  },
  beforeMount() {
    this.blogList(this.$route.query.category);
  },
  created () {
    let currentUrl = window.location.href;
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical !== null) {
        canonical.href = currentUrl;
    }
  },
}
</script>