<template>
    <div>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12 text-center">
                        <nav aria-label="breadcrumb" class="float-right">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0)" @click="$router.push({ path: '/'})">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Power Chat</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title position-relative pb-3 mb-3">
                            <h1>SECURE COMMUNICATION BUILT FOR BUSINESS</h1>
                        </div>
                        <p>Instant messaging is unavoidable in this fast-paced digital world, and in many ways, it has replaced the email culture in the workplace. With Power Chat, your corporate workspace can communicate without external interference, just like a <b>private chat messenger.</b></p>
                        <p>The secure and privacy-compliant messaging application helps employees communicate efficiently and quickly without the danger of data leaks or privacy breaches. The effective end-to-end encryption and metadata protection deliver robust security around the instant messenger app.</p>
                        <h4><b>WHY USE POWER CHAT?</b></h4>
                        <p>The <b>best private texting apps,</b> like Power Chat, offer solid value to businesses in the private and public sectors through their multi-faceted benefits.</p>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Reduce Hacking:</b> Cyber security is a huge deal in the corporate world, and using Power Chat is one way to minimize any hacking possibility within the organization. Utilise <b>free private chat</b> with added cyber security.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Minimize Security Vulnerabilities:</b> Every digital system has a vulnerability that can be exploited by external threats. To prevent such vulnerabilities, getting the office network under a secure messaging app is one effective way.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Reputation Management:</b> Corporates have an external reputation to uphold, and to do so, the internal affairs must be kept internal. To keep in-house communications within the workplace, and to prevent unauthorized leaks or data transfer, a secure and encrypted communication system is necessary.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Privacy:</b> The Power Chat app is designed to keep conversations private. So, there is no way an outsider who is not part of the conversation can eavesdrop and hear in on the company's secrets.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Encryption Protection:</b> Once the sender sends a message, it is done after being encrypted on the sender's device. The message can only be decrypted at the receiver's device, so anyone who intercepts the message will be left with nothing but gibberish, even if it is the service provider.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Avoid Commercial Data Mining:</b> Commonly used messaging apps with little to no security can collect data that advertisers and other commercial entities purchase for their use. With a secure messaging app like Power Chat, the data is not collected, thus reducing digital footprint and exposure to targeted commercial marketing agencies.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Added Security Features:</b> Other than encryption, there are additional security features like disappearing messages, self-destructing messages, screenshot blocking, secret chats, and secure file sharing. These add an additional layer of privacy for sharing sensitive data.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Protection from Identity Theft:</b> There is a strong verification process that ensures you are talking with the person that you know. Layers like two-factor authentication and verification keys ensure that only the authorized person can use the app.</h6>
                        </div>
                        <p>With a <b>free private chat app,</b> you can chat with anyone across the globe without compromising on security.</p>
                        <h4><b>Key features:</b></h4>
                        <div class="row g-0 mb-3">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Privacy:</b> Contacts cannot communicate with outside groups even if the other party uses Powerchat. No need for mobile number or email address. User ID can be anonymous</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Encryption:</b> Whole communication is encrypted to provide the ultimate level of security from threat actors.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Media Vault:</b> All user media files ( Audio, video, pictures, documents) can be safely stored in the users app vault. Android file system and other apps cannot access the media file.  A separate password is needed to access media files.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Security:</b> Application PIN is enabled based on time and when app goes to background. This ensured that there is no unauthorized access.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Cloud controls:</b> Complete user and license management.  Users can be added and deleted. All users create by the Power chat license purchaser will belong to a single virtual organization.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Login using QRCODE:</b> User can login using QRCODES and can also add other contacts using QRCODE.</h6>
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Message Burn:</b> Chat messages can be configured to disappear on receipt or time. No trace of the message is available on the device or any servers.</h6>
                        </div>
                        <h4><b>Cloud usage guide</b></h4>
                        <ol>
                            <li>Purchase or try the Power chat from package disabler website. Then login to the cloud portal (<a href="https://app.packagedisabler.com">https://app.packagedisabler.com</a>) with your credentials to manage users.</li>
                            <li>
                                Please select the Power chat menu on the left side panel and press the  CREATE USER BUTTON.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="aboutPChatImage" alt="About Power Chat">
                                </div>
                            </li>
                            <li>
                                Provide the user an identifiable name, an ID and a password. Name is a display name for your reference. ID and password is needed for application login.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="createPChatUser" alt="Create Power Chat User">
                                </div>
                            </li>
                            <li>
                                Once the user is created, you can this user in a list with its creation date and ability to obtain the login QRCODE and to delete the user.
                                <b>Please scan the QRCODE from the power chat app to login.</b>
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="listPChatUser" alt="List Power Chat Users">
                                </div>
                            </li>
                        </ol>
                        <h3><b>Application Guide</b></h3>
                        <ol>
                            <li>Download Power chat from the purchase email or from the cloud portal and install it.</li>
                            <li>
                                Launch the Power chat app and press the scan icon to scan the QRCODE or enter the user id and password to login.
                                <div class="position-relative h-100 mt-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appLogin" alt="Power Chat Login">
                                </div>
                            </li>
                            <li>
                                <b>MAIN screen: </b> Upon login, three menus will be displayed, chats, contacts and vault. 
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appMainScreen" alt="Power Chat Home Page">
                                </div>
                            </li>
                            <li>
                                <b>CONTACT screen: </b> Contacts need to be added from the contact menu by search and add or by scanning QRCODE. The QRCODE to share with your contact can be obtained from the profile page that can be accessed by clicking the man icon on the top of the chat app.
                                <div class="position-relative h-100 m-2">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appAddContactScreen" alt="Add Contact">&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appListContactScreen" alt="List Contact">
                                </div>
                                <p>The user request is sent when the user is added. You can delete a contact from this page. When a contact request is received it has to be approved to start conversation with the contact.</p>
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appAcceptContactScreen" alt="Invitation List Page">
                                </div>
                            </li>
                            <li>
                                <b>CHAT screen: </b> To start a conversation, please click on the contact to open a conversation screen. Media files can be sent from the camera ,gallery, document, video and vault.Click on the mic icon to send audio recordings. Clicking on the mask icon enables disappearing messages that the deleted once the contact views the message. 
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appChatScreen" alt="Chat Screen">&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appChatMediaOptionScreen" alt="Chat Media">
                                </div>
                                <p>More chat options are available by clicking the settings icon on the top right. Each message can be selected and upon selection the options to forward, copy are delete can be made available.</p>
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appChatMoreOptionScreen" alt="Chat More Options">
                                </div>
                            </li>
                            <li>
                                <b>Vault screen: </b> All media files are stored in a password protected safe storage. 
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appVaultScreen" alt="Vault">&nbsp;
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appVaultListScreen" alt="Application Vault">
                                </div>
                            </li>
                            <li>
                                <b>Profile Settings screen: </b> This can be accessed by clicking the main icon from the main screen.Application password, PIN for app timeout, sound modulation and logout option are listed. The QRCODE to share with other contact is also shown on this page.
                                <div class="position-relative h-100">
                                    <img class="img-fluid" data-wow-delay="0.9s" :src="appProfileScreen" alt="Profile Page">
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
    </div>
</template>
<script>
export default {
    props: {
        aboutPChatImage: {
            type: String,
            default: "./img/powerchat/powerchatinfo.png",
        },
        createPChatUser: {
            type: String,
            default: "./img/powerchat/CreatePchatUser.png",
        },
        listPChatUser: {
            type: String,
            default: "./img/powerchat/PchatListUsers.png",
        },
        appLogin: {
            type: String,
            default: "./img/powerchat/AppLogin.png",
        },
        appMainScreen: {
            type: String,
            default: "./img/powerchat/AppMainScreen.png",
        },
        appAddContactScreen: {
            type: String,
            default: "./img/powerchat/AppAddContact.png",
        },
        appListContactScreen: {
            type: String,
            default: "./img/powerchat/AppContactList.png",
        },
        appAcceptContactScreen: {
            type: String,
            default: "./img/powerchat/AppAcceptRequest.png",
        },
        appChatScreen: {
            type: String,
            default: "./img/powerchat/AppChatScreen.png",
        },
        appChatMediaOptionScreen: {
            type: String,
            default: "./img/powerchat/AppChatMediaOption.png",
        },
        appChatMoreOptionScreen: {
            type: String,
            default: "./img/powerchat/AppChatMoreOptions.png",
        },
        appVaultScreen: {
            type: String,
            default: "./img/powerchat/AppVaultPassword.png",
        },
        appVaultListScreen: {
            type: String,
            default: "./img/powerchat/AppVaultList.png",
        },
        appProfileScreen: {
            type: String,
            default: "./img/powerchat/AppProfile.png",
        }
    },
    created () {
        /*let currentUrl = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.href = currentUrl;
        }
        const metaDescEl = document.querySelector('head meta[name="description"]');
        metaDescEl.setAttribute('content', "Power Chat is the best private messenger featuring end-to-end encryption. Elevate your messaging experience with the top-rated secure app for private communication.");
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        metaTitleEl.setAttribute('content', "Power Chat | The Best Private Messenger with End-to-End Encryption");
        const metaKeywordsEl = document.querySelector('head meta[name="keywords"]');
        metaKeywordsEl.setAttribute('content', "Power Chat, Private chat messenger, secure messaging apps, best private texting app, best private messaging app, end-to-end encryption messenger, best private text messaging app");*/
    },    
}
</script>